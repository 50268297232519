import {
  FaCheck,
  FaUndoAlt,
  FaExclamationTriangle,
  FaHandPointRight,
  FaChevronRight,
  FaPrint,
  FaPhone,
  FaEye,
} from "react-icons/fa";
import { FaPenToSquare } from "react-icons/fa6";
import { FcSearch } from "react-icons/fc";

const FaIcons = {
  FaCheck,
  FaChevronRight,
  FaExclamationTriangle,
  FaHandPointRight,
  FaPrint,
  FaUndoAlt,
  FaPhone,
  FaEye,
  FaPenToSquare,
  FcSearch,
};

export default FaIcons;
