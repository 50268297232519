import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SideMenu from "../../components/menus/m-side";
import { useNavHeight } from "../../_utils/context/navheightprovider";
import tableSaisons from "../../_params/tab-config/tab-saisons";
import tabCsvFiles from "../../_params/tab-pages/members/tab-csvFiles";
import Papa from "papaparse";
import FaIcons from "../../assets/icons/faicons";
import MemberSlipModal from "../../components/pages/admin/memberslipmodal";

const ListeDesMembres = () => {
  const { navbarHeight } = useNavHeight();
  const [currentSeason, setCurrentSeason] = useState(0);
  const [tabRows, setTabRows] = useState([]);
  const [tabValues, setTabValues] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [member, setMember] = useState({});

  useEffect(() => {
    let year = 0;

    /*************************** Selection of today's date to select current season year */
    const todaysDate = new Date();
    for (let i = 0; i < tableSaisons.length; i++) {
      const startDate = Date.parse(tableSaisons[i].startDate);
      const endDate = Date.parse(tableSaisons[i].endDate);

      if (todaysDate > startDate && todaysDate < endDate) {
        year = tableSaisons[i].season;
        break;
      }
    }

    setCurrentSeason(year);
    const yearIndex = tabCsvFiles.findIndex((item) => item.season === year);
    const csvFile = tabCsvFiles[yearIndex].file;

    Papa.parse(csvFile, {
      download: true,
      header: true,
      dynamicTyping: true,
      delimiter: "",
      skipEmptyLines: true,
      complete: (result) => {
        const rowsArray = [];
        const valuesArray = [];

        result.data.map((d) => {
          return (
            rowsArray.push(Object.keys(d)),
            valuesArray.push(
              Object.values({
                ...d,
                // residence: d.residence.replaceAll(" ", "\u00a0"),
                street: d.street.replaceAll(" ", "\u00a0"), // \u00a0  refers to the Unicode character of &nbsp;
                city: d.city.replaceAll(" ", "\u00a0"),
              })
            )
          );
        });
        setTabRows(rowsArray[0]);
        setTabValues(valuesArray);
      },
    });
  }, []);

  //
  /* Récupération des saisons présentes dans le fichier des membres */
  //
  const tabSaison = [];
  let season = "";

  tabCsvFiles.map((saison) => {
    if (saison.season !== season) {
      season = saison.season;

      tabSaison.push({ season: saison.season });
    }
    return {
      tabSaison,
    };
  });
  /* FIN - Récupération des saisons existant dans la table des fichiers des membres */

  const handleSeasonChange = (e) => {
    const yearIndex = tabCsvFiles.findIndex(
      (item) => item.season === e.target.value
    );
    const csvFile = tabCsvFiles[yearIndex].file;

    Papa.parse(csvFile, {
      download: true,
      header: true,
      dynamicTyping: true,
      delimiter: "",
      skipEmptyLines: true,
      complete: (result) => {
        const rowsArray = [];
        const valuesArray = [];

        result.data.map((d) => {
          return (
            rowsArray.push(Object.keys(d)),
            valuesArray.push(
              Object.values({
                ...d,
                // residence: d.residence.replaceAll(" ", "\u00a0"),
                street: d.street.replaceAll(" ", "\u00a0"), // \u00a0  refers to the Unicode character of &nbsp;
                city: d.city.replaceAll(" ", "\u00a0"),
              })
            )
          );
        });

        setTabRows(rowsArray[0]);
        setTabValues(valuesArray);
      },
    });
    setCurrentSeason(e.target.value);
  };

  const handleShowMember = (value) => (e) => {
    setModalShow(true);
    setMember(value);
  };

  return (
    <div className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            xs={12}
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>

          <Col xs={12} md={9} className="g-0">
            <Row className="justify-content-center align-items-center text-center text-primary bg-light m-4 p-2 rounded mc-rampart">
              <Col md={9} className="g-0">
                <h1 className="m-0 mc-rfs-1">LISTE DES MEMBRES</h1>
              </Col>
              <Col md={3} className="g-0">
                <Form.Select
                  className="mc-rfs-3 m-auto text-center text-primary bg-secondary-subtle"
                  name="currentSeason"
                  value={currentSeason}
                  onChange={handleSeasonChange}
                >
                  {tabSaison.map((saison, i) => {
                    return <option key={i}>{saison.season}</option>;
                  })}
                </Form.Select>
              </Col>
            </Row>

            <Card bg="transparent" className="my-2 border-0 mc-rfs-5">
              <Card.Body>
                <Table responsive bordered>
                  <thead>
                    <tr>
                      <th className="align-middle text-center text-light">
                        <FaIcons.FaEye />
                      </th>
                      {tabRows.map((row, i) => {
                        return <th key={i}>{row}</th>;
                      })}
                    </tr>
                  </thead>

                  <tbody className="bg-success">
                    {tabValues.map((value, i) => (
                      <tr key={i}>
                        <td className="py-0 align-middle text-center">
                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip className="mc-rfs-7">
                                Ouvrir la fiche
                              </Tooltip>
                            }
                          >
                            <div
                              className="p-2"
                              style={{ cursor: "pointer" }}
                              onClick={handleShowMember(value)}
                            >
                              <FaIcons.FaEye />
                            </div>
                          </OverlayTrigger>
                        </td>
                        {value.map((val, i) => {
                          return (
                            <td
                              key={i}
                              className={
                                i > value.length - 4
                                  ? "py-0 align-middle text-center"
                                  : "py-0 align-middle"
                              }
                            >
                              {val}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <MemberSlipModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        member={member}
      />
    </div>
  );
};

export default ListeDesMembres;
