import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import FaIcons from "../../../assets/icons/faicons";

const DetailOffre = (values) => {
  return (
    <Container fluid className="gx-0">
      <Row className="mx-2">
        <Card className="my-3 text-center border border-5 border-dark-subtle rounded bg-danger opacity-100">
          <Card.Title className="mc-rfs-3 m-0 p-2 text-light fw-bold">
            Synthèse de l'offre
          </Card.Title>
        </Card>
        <Card className="mt-2 mb-1">
          <Card.Body className="mc-rfs-5 px-0">
            <Card.Title className="text-center fw-bold">
              Récapitulatif de la demande
            </Card.Title>
            {values.values.licence && (
              <Card.Body className="d-flex justify-content-start align-items-center p-0">
                <FaIcons.FaHandPointRight className="me-2" />
                <Card.Text>Licence FFT {values.values.licence}</Card.Text>
              </Card.Body>
            )}
            {values.values.membership && (
              <Card.Body className="d-flex justify-content-start align-items-center p-0">
                <FaIcons.FaHandPointRight className="me-2" />
                <Card.Text>{values.values.membership}</Card.Text>
              </Card.Body>
            )}
            {values.values.period && (
              <Card.Body className="d-flex justify-content-start align-items-center p-0">
                <FaIcons.FaHandPointRight className="me-2" />

                <Card.Text>
                  {values.values.period === "Saison"
                    ? "Adhésion pour la saison"
                    : `Adhésion à partir de : ${values.values.periodMonth}`}
                </Card.Text>
              </Card.Body>
            )}
            {values.values.offer && (
              <Card.Body className="d-flex justify-content-start align-items-center p-0">
                <FaIcons.FaHandPointRight className="me-2" />
                <Card.Text>
                  {values.values.offer
                    ? `${values.values.offerNumPerson} personne${
                        values.values.offerNumPerson > 1 ? "s" : ""
                      } en ${values.values.offer}`
                    : null}
                </Card.Text>
              </Card.Body>
            )}
            {values.values.membershipNumHours > 0 && (
              <Card.Body className="d-flex justify-content-start align-items-center p-0">
                <FaIcons.FaHandPointRight className="me-2" />
                <Card.Text>
                  {values.values.membershipNumHours} heure
                  {values.values.membershipNumHours > 1 ? "s" : ""} de cours par
                  semaine
                </Card.Text>
              </Card.Body>
            )}
            {values.values.badge && (
              <Card.Body className="d-flex justify-content-start align-items-center p-0">
                <FaIcons.FaHandPointRight className="me-2" />
                <Card.Text>
                  {values.values.badgeNum} badge
                  {values.values.badgeNum > 1 ? "s" : ""} pour les courts
                  couverts
                </Card.Text>
              </Card.Body>
            )}
            {values.values.key && (
              <Card.Body className="d-flex justify-content-start align-items-center p-0">
                <FaIcons.FaHandPointRight className="me-2" />
                <Card.Text>
                  {values.values.keyNum} clé
                  {values.values.keyNum > 1 ? "s" : ""} pour les courts
                  extérieurs
                </Card.Text>
              </Card.Body>
            )}
          </Card.Body>
        </Card>
        {/* {values.values.offer && ( */}
        <Card className="my-1">
          <Card.Body className="mc-rfs-5 px-0">
            <Card.Title className="px-0 text-center fw-bold">
              Montant de l'adhésion
            </Card.Title>
            {!values.btnToggle ? (
              <Card.Body className="px-1 py-2 text-center bg-danger text-light fst-italic rounded lh-1">
                <Card.Text className="m-0">
                  <FaIcons.FaExclamationTriangle className="mc-rfs-1" />
                </Card.Text>
                <Card.Text className="m-1">
                  Attention le montant de l'adhésion ne correspond pas aux
                  modifications apportées.
                </Card.Text>
                <Card.Text>Appuyez sur le bouton "calcul de l'offre"</Card.Text>
              </Card.Body>
            ) : null}
            {values.values.licence &&
              values.values.membership &&
              values.values.offer &&
              values.values.offerNumPerson !== "0" && (
                <Card.Header className="px-0 d-flex align-items-center justify-content-between">
                  <Card.Text className="m-0 w-75">
                    {values.values.membership === "Adhésion seule"
                      ? values.values.membership
                      : `Adhésion + ${values.values.membershipNumHours} ${
                          values.values.membershipNumHours > 1
                            ? "heures"
                            : "heure"
                        } de cours`}{" "}
                    pour {values.values.offerNumPerson} personne
                    {values.values.offerNumPerson !== "1" ? "s" : ""}, en
                    catégorie {values.values.offer}{" "}
                    {values.values.period === "Saison"
                      ? `pour la ${values.values.period}`
                      : `à partir de ${values.values.periodMonth}`}
                  </Card.Text>
                  <Card.Text className="m-0 fw-bold">
                    {values.totalOffer.tariff} €
                  </Card.Text>
                </Card.Header>
              )}
            {values.values.badge && (
              <Card.Header className="px-0 d-flex align-items-center justify-content-between">
                <Card.Text className="m-0 w-75">
                  Caution pour {values.values.badgeNum} badge
                  {values.values.badgeNum > 1 ? "s" : ""} :
                </Card.Text>
                <Card.Text className="m-0 fw-bold">
                  {values.totalOffer.totBadge} €
                </Card.Text>
              </Card.Header>
            )}
            {values.values.key && (
              <Card.Header className="px-0 d-flex align-items-center justify-content-between">
                <Card.Text className="m-0 w-75">
                  Caution pour {values.values.keyNum} clé
                  {values.values.keyNum > 1 ? "s" : ""} :
                </Card.Text>
                <Card.Text className="m-0 fw-bold">
                  {values.totalOffer.totKey} €
                </Card.Text>
              </Card.Header>
            )}

            {values.totalOffer.totAnimation > 0 && (
              <Card.Header className="px-0 d-flex align-items-center justify-content-between">
                <Card.Text className="m-0 w-75">
                  Participation animations :
                </Card.Text>
                <Card.Text className="m-0 fw-bold">
                  {values.totalOffer.totAnimation} €
                </Card.Text>
              </Card.Header>
            )}
          </Card.Body>
          <Card.Footer className="px-0 d-flex align-items-center justify-content-between fw-bolder">
            <Card.Text className="m-0 w-75">TOTAL</Card.Text>
            <Card.Text className="m-0">
              {values.totalOffer.tariff +
                values.totalOffer.totBadge +
                values.totalOffer.totKey +
                values.totalOffer.totAnimation}{" "}
              €
            </Card.Text>
          </Card.Footer>
        </Card>

        {/* <Card className="my-3 opacity-100">
          <Card.Body>
            <pre>{JSON.stringify(values, null, 2)}</pre>
          </Card.Body>
        </Card> */}
      </Row>
    </Container>
  );
};

export default DetailOffre;
