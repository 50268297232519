import React from "react";

const WorkInProgress = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center text-light bg-secondary"
      style={{ height: "100vh" }}
    >
      <h1 className="mc-flashing-text">Work In Progress</h1>
    </div>
  );
};

export default WorkInProgress;
