const tabColors = [
  {
    colorId: 0,
    rgb: "rgb(203, 170, 80)",
    rgba: "rgba(203, 170, 80, 1)",
    hexa: "#CBAA50",
  },
  {
    colorId: 1,
    rgb: "rgb(203, 100, 80)",
    rgba: "rgba(203, 100, 80, 1)",
    hexa: "#CB6450",
  },
  {
    colorId: 2,
    rgb: "rgb(203, 60, 80)",
    rgba: "rgba(203, 60, 80, 1)",
    hexa: "#CB3C50",
  },
  {
    colorId: 3,
    rgb: "rgb(135, 0, 0)",
    rgba: "rgba(135, 0, 0, 1)",
    hexa: "#870000",
  },
  {
    colorId: 4,
    rgb: "rgb(135, 99, 45)",
    rgba: "rgba(135, 99, 45)",
    hexa: "#87632d",
  },
  {
    colorId: 5,
    rgb: "rgb(135, 48, 11)",
    rgba: "rgba(135, 48, 11)",
    hexa: "#87300B",
  },
];

export default tabColors;
