const tarifInitValues = {
  licence: "",
  membership: "",
  membershipNumHours: "",
  period: "",
  periodMonth: "",
  offer: "",
  offerNumPerson: "",
  badge: false,
  badgeNum: "",
  key: false,
  keyNum: "",
  animation: 1,
};

export default tarifInitValues;
