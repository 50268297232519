import clubHouse1 from "../../../assets/img/img-installations/clubHouse1.jpg";
import clubHouse2 from "../../../assets/img/img-installations/clubHouse2.jpg";
import clubHouse3 from "../../../assets/img/img-installations/clubHouse3.jpg";
import clubVuDuCiel from "../../../assets/img/img-installations/clubVuDuCiel.jpg";
import courtsCouverts from "../../../assets/img/img-installations/courtsCouvert.jpg";
import courtsCouvertsTCF from "../../../assets/img/img-installations/courtsCouvertsTCF.png";
import courtsExterieurs from "../../../assets/img/img-installations/courtsExterieurs.jpg";
import courtsExterieurs1 from "../../../assets/img/img-installations/courtsExterieurs1.png";

const tabInstallations = [
  { id: "clubHouse1", image: clubHouse1 },
  { id: "clubHouse2", image: clubHouse2 },
  { id: "clubHouse3", image: clubHouse3 },
  { id: "clubVuDuCiel", image: clubVuDuCiel },
  { id: "courtsCouvertsTCF", image: courtsCouvertsTCF },
  { id: "courtsCouverts", image: courtsCouverts },
  { id: "courtsExterieurs", image: courtsExterieurs },
  { id: "courtsExterieurs1", image: courtsExterieurs1 },
];

export default tabInstallations;
