const tableSaisons = [
  {
    id: 1,
    season: "2013",
    startDate: "2012-09-01",
    endDate: "2013-08-31",
  },
  {
    id: 2,
    season: "2017",
    startDate: "2016-09-01",
    endDate: "2017-08-31",
  },
  {
    id: 3,
    season: "2018",
    startDate: "2017-09-01",
    endDate: "2018-08-31",
  },
  {
    id: 4,
    season: "2019",
    startDate: "2018-09-01",
    endDate: "2019-08-31",
  },
  {
    id: 5,
    season: "2020",
    startDate: "2019-09-01",
    endDate: "2020-08-31",
  },
  {
    id: 6,
    season: "2021",
    startDate: "2020-09-01",
    endDate: "2021-08-31",
  },
  {
    id: 7,
    season: "2022",
    startDate: "2021-09-01",
    endDate: "2022-08-31",
  },
  {
    id: 8,
    season: "2023",
    startDate: "2022-09-01",
    endDate: "2023-08-31",
  },
  {
    id: 9,
    season: "2024",
    startDate: "2023-09-01",
    endDate: "2024-08-31",
  },
  {
    id: 10,
    season: "2025",
    startDate: "2024-09-01",
    endDate: "2025-08-31",
  },
];

export default tableSaisons;
