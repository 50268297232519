//
// Fonction pour écouter les mises à jour du formulaire *********************
//
import { useFormikContext } from "formik";
import { useEffect } from "react";

const FormObserver = ({ setBtnToggle }) => {
  const { values } = useFormikContext();
  // const [btnColor, setBtnColor] = useState(true);

  useEffect(() => {
    setBtnToggle(false);
  }, [values, setBtnToggle]);

  return null;
};

export default FormObserver;
