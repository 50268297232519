import { Image } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import TopPageArrow from "../../assets/icons/icons8-thick-arrow-pointing-up-64.png";

const BtnTopPage = () => {
  return (
    <Nav className="position-fixed bottom-0 start-0" style={{ zIndex: 1000 }}>
      <Nav.Item className="m-3">
        <Nav.Link
          href="#mcTopPage"
          className="mc-top-page p-0 rounded border border-4 border-dark-subtle"
        >
          <div
            style={{
              height: "35px",
              width: "35px",
            }}
          >
            <Image className="rounded-0" src={TopPageArrow} thumbnail />
          </div>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default BtnTopPage;
