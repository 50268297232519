import React from "react";

const ErrorPage = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center text-light bg-secondary"
      style={{ height: "100vh" }}
    >
      <h1 className="mc-flashing-text">Page not found!</h1>
    </div>
  );
};

export default ErrorPage;
