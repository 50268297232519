import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosFeteEcole from "../../../_params/tab-pages/animations/tab-feteecole";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";

const FeteEcole = () => {
  //
  /* Tri de la table des photos de fin d'année en ordre décroissant */
  //
  const photosSorted = photosFeteEcole.sort((a, b) => b.id - a.id);

  //
  /* Récupération des saisons existant dans la table photos de fin d'année */
  //
  const tabSaison = [];

  photosSorted.map((saison) => {
    let season = "";
    if (saison.id !== season) {
      season = saison.id;
      /* La propriété id est nécessaire pour compatibilité avec les autres tables utilisant MenuSaison */
      tabSaison.push({ id: saison.id, season: saison.id });
    }
    return {
      tabSaison,
    };
  });
  return (
    <div className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col className="gx-0">
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumbEvents />
            {/************************** Partie Fete Ecole */}
            <Card bg="secondary" className="m-2 border-0">
              <Card.Header className="mc-rampart mc-rfs-2 m-3 text-primary rounded-0 text-center bg-white">
                Fête de l'école de TENNIS - fin juin
              </Card.Header>
            </Card>
            {/************************** Partie Fête École */}
            {photosSorted.map((saison, index) => {
              return (
                <Card key={index} bg="transparent" className="m-2 border-0">
                  <Card.Body
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                    className="m-2 p-4"
                  >
                    <PhotoAlbum layout="rows" photos={saison.images} />
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FeteEcole;
