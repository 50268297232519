import * as Yup from "yup";

const tarifSchema = Yup.object().shape({
  licence: Yup.string().required("Choisir le type de licence"),
  membership: Yup.string().required("Choisir le type d'adhésion"),
  membershipNumHours: Yup.string().when("membership", {
    is: (val) => val === "Adhésion + cours",
    then: () => Yup.string().required("Choisir le nb d'heures souhaitées"),
    otherwise: () => Yup.string(),
  }),
  period: Yup.string().required("Choisir une période"),
  periodMonth: Yup.string().when("period", {
    is: (val) => val === "Autre",
    then: () => Yup.string().required("Choisir le mois de référence"),
    otherwise: () => Yup.string(),
  }),
  offer: Yup.string().required("Choisir le type d'offre"),
  offerNumPerson: Yup.string().when("offer", {
    is: (val) => val !== "",
    then: () => Yup.string().required("Indiquer le nb de personnes"),
    otherwise: () => Yup.string(),
  }),
  badge: Yup.boolean(),
  badgeNum: Yup.string().when("badge", {
    is: true,
    then: () => Yup.string().required("Fournir le nb de badges souhaités"),
    otherwise: () => Yup.string(),
  }),
  key: Yup.boolean(),
  keyNum: Yup.string().when("key", {
    is: true,
    then: () => Yup.string().required("Fournir le nb de clés souhaitées"),
    otherwise: () => Yup.string(),
  }),
  animation: Yup.string(),
});

export default tarifSchema;
