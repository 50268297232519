import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../_utils/tools/use-window-size";
import Nav from "react-bootstrap/Nav";
import tabMenus from "../../_params/tab-config/tab-menus";
import { useNavHeight } from "../../_utils/context/navheightprovider";
import "../../assets/css/menu-club.css";

const SideMenu = () => {
  const { pathname, hash } = useLocation();
  const navRef = useRef(null);
  // Variable permettant l'apparition ou non du "burger"
  const [showLinks, setShowLinks] = useState(false);

  // récupération de la taille de l'écran à partir du hook "useWindowSize".
  const windowWidth = useWindowSize().width;

  // récupération de la variable globale contexte **********************/
  const {
    navbarHeight,
    sideNavHeight,
    breadNavHeight,
    updateSideNavHeight,
    updateNavbarHeight,
  } = useNavHeight();

  useEffect(() => {
    const menuHeight = navRef?.current.offsetHeight;
    updateSideNavHeight(0);

    if (windowWidth <= 767) {
      updateSideNavHeight(menuHeight ?? 0);
    }
  }, [updateSideNavHeight, updateNavbarHeight, windowWidth]);

  /********************************************************************/
  /******* Filtre de la table menu pour les menus latéraux ************/
  /********************************************************************/
  let filteredSideMenu = [{ path: "", menus: [{}] }];

  tabMenus.forEach((menu) => {
    if (menu.name === "sidemenu") {
      if (pathname.startsWith(menu.path)) {
        filteredSideMenu = [{ path: menu.path, menus: [...menu.menus] }];
      }
    }
  });

  /********************************************************************/

  // A chaque clic de lien pour changer de route/location le useEffect est actionné.
  useEffect(() => {
    setShowLinks(false);
  }, []);

  // mise à jour de la variable permettant l'apparition ou non du "burger" lorsque la taille de l'écran est < 576.
  useEffect(() => {
    if (showLinks) {
      if (windowWidth <= 270) {
        setShowLinks(true);
      } else {
        setShowLinks(false);
      }
    }
  }, [windowWidth, showLinks]);

  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <div ref={navRef} className={`mc-menu${showLinks ? " mc-show-nav" : ""}`}>
      <div
        style={
          windowWidth > 767
            ? {
                position: "sticky",
                top: navbarHeight,
              }
            : null
        }
      >
        <Nav
          className="mc-menu-club"
          style={
            windowWidth > 767
              ? {
                  position: "sticky",
                  top: sideNavHeight + breadNavHeight,
                }
              : {
                  top: sideNavHeight,
                }
          }
        >
          {filteredSideMenu.map((item) => {
            return item.menus.map((menu, index) => (
              <Nav.Item
                key={index}
                className={`mc-menu-item my-2 mc-slideInDown-${index + 1}`}
              >
                {menu.showTitle ? (
                  <div
                    className={`mc-rfs-4 text-center text-warning fw-bolder ${
                      showLinks ? "mt-3" : null
                    }`}
                  >
                    {menu.title}
                  </div>
                ) : null}
                {menu.links.map((link, index) => (
                  <div
                    key={index}
                    className={`lh-sm mc-slideInDown-${index + 1}`}
                  >
                    {link.subPath.charAt(0) === "#" ? (
                      <a
                        className={`py-1 nav-link text-light
                        ${hash === link.subPath ? " mc-active-link" : ""}`}
                        href={`${link.subPath}`}
                      >
                        <span className="mc-rfs-5 text-center">
                          {link.linkName}
                        </span>
                      </a>
                    ) : (
                      <NavLink
                        className={({ isActive }) =>
                          [
                            "py-1 nav-link",
                            isActive ? "mc-active-link" : "text-light",
                          ].join(" ")
                        }
                        to={`${item.path}${link.subPath}`}
                      >
                        <span className="mc-rfs-5 text-center">
                          {link.linkName}
                        </span>
                      </NavLink>
                    )}
                  </div>
                ))}
              </Nav.Item>
            ));
          })}
        </Nav>
        <button
          className="mc-menu-burger"
          type="button"
          onClick={handleShowLinks}
        >
          <span className="mc-burger-bar"></span>
        </button>
      </div>
    </div>
  );
};

export default SideMenu;
