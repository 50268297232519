import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import tabMenus from "../../_params/tab-config/tab-menus";
import { useNavHeight } from "../../_utils/context/navheightprovider";
import "../../assets/css/menu-club.css";

const NavbarSection = () => {
  const navRef = useRef(null);
  const navbarRef = useRef(null);

  // récupération de la variable globale contexte **********************/
  const { updateNavbarHeight } = useNavHeight();

  useEffect(() => {
    const menuHeight = navbarRef?.current.offsetHeight;
    updateNavbarHeight(menuHeight ?? 0);
  }, [updateNavbarHeight]);

  /********************************************************************/
  /******************* Filtre de la table menu pour navbar ************/
  /********************************************************************/
  let filteredMenu = [{ path: "", menus: [{}] }];

  tabMenus.forEach((menu) => {
    if (menu.name === "navbar") {
      filteredMenu = [{ path: menu.path, menus: [...menu.menus] }];
    }
  });
  /********************************************************************/

  /* Gestion du menu horizontal */
  if (navRef.current) {
    // if (navbarActiveLink !== "/") {
    let activeLink = document.querySelector(".mc-active-link");
    activeLink?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
    // }
  }

  return (
    <Navbar
      bg="primary bg-gradient"
      variant="dark"
      className="border-0 border-bottom border-1 mc-scrollbar"
      sticky="top"
      ref={navbarRef}
    >
      <Container fluid>
        <Nav className="pe-3 mc-nav-hover mc-navref" ref={navRef}>
          {filteredMenu.map((item) => {
            return item.menus.map((menu) => {
              return menu.links.map((link, index) => {
                return (
                  <NavLink
                    key={link.subPath}
                    className={({ isActive }) =>
                      [
                        "mx-2 px-2 text-center text-nowrap nav-link mc-rfs-5",
                        isActive ? "mc-active-link" : "text-light",
                      ].join(" ")
                    }
                    to={`${item.path}${link.subPath}`}
                  >
                    {link.linkName}
                  </NavLink>
                );
              });
            });
          })}
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavbarSection;
