import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../_utils/tools/use-window-size";
import Nav from "react-bootstrap/Nav";
import tabSeasons from "../../_params/tab-config/tab-saisons";
import { useNavHeight } from "../../_utils/context/navheightprovider";
import "../../assets/css/menu-club.css";

const MenuSaison = ({ saisons }) => {
  const { hash } = useLocation();

  const navRef = useRef(null);
  // Variable permettant l'apparition ou non du "burger"
  const [showLinks, setShowLinks] = useState(false);
  // Variable permettant l'activation du lien cliqué dans le menu latéral.

  // récupération de la taille de l'écran à partir du hook "useWindowSize".
  const windowWidth = useWindowSize().width;

  // récupération de la variable globale contexte **********************/
  const { navbarHeight, sideNavHeight, breadNavHeight, updateSideNavHeight } =
    useNavHeight();

  useEffect(() => {
    const menuHeight = navRef?.current.offsetHeight;

    if (windowWidth <= 767) {
      updateSideNavHeight(menuHeight ?? 0);
    } else {
      updateSideNavHeight(0);
    }
  }, [updateSideNavHeight, windowWidth]);

  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };

  // A chaque clic de lien pour changer de route/location le useEffect est actionné.
  useEffect(() => {
    setShowLinks(false);
  }, []);

  // mise à jour de la variable permettant l'apparition ou non du "burger" lorsque la taille de l'écran est >= 576.
  useEffect(() => {
    if (showLinks) {
      if (windowWidth <= 270) {
        setShowLinks(true);
      } else {
        setShowLinks(false);
      }
    }
  }, [windowWidth, showLinks]);

  // Choix de la table des saisons à prendre en compte pour le menu
  let tabSaison = [];
  if (saisons) {
    tabSaison = saisons;
  } else {
    tabSaison = tabSeasons;
  }

  return (
    <div ref={navRef} className={`mc-menu${showLinks ? " mc-show-nav" : ""}`}>
      <div
        style={
          windowWidth > 767
            ? {
                position: "sticky",
                top: navbarHeight,
              }
            : null
        }
      >
        <Nav
          className="mc-menu-club"
          style={
            windowWidth <= 767 && windowWidth > 270
              ? {
                  position: "sticky",
                  top: sideNavHeight + breadNavHeight,
                }
              : {
                  top: sideNavHeight,
                }
          }
        >
          <Nav.Item className="mc-menu-item mc-slideInDown-1">
            {tabSaison.map((saison, index) => {
              return (
                <div
                  key={index}
                  className={`my-2 lh-1 mc-slideInDown-${index + 1}`}
                >
                  <a
                    className={`py-2 nav-link text-light"
                     ${
                       hash === `#saison${saison.season}`
                         ? " mc-active-link"
                         : ""
                     }`}
                    href={`#saison${saison.season}`}
                  >
                    <span
                      className={`mc-rfs-5  text-center ${
                        hash === `#saison${saison.season}` ? "fw-semibold" : ""
                      }`}
                    >
                      {`Saison ${saison.season}`}
                    </span>
                  </a>
                </div>
              );
            })}
          </Nav.Item>
        </Nav>
        <button
          className="mc-menu-burger"
          type="button"
          onClick={handleShowLinks}
        >
          <span className="mc-burger-bar"></span>
        </button>
      </div>
    </div>
  );
};

export default MenuSaison;
