import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import MemberSlip from "./memberslip";
import tabCsvFiles from "../../../_params/tab-pages/members/tab-csvFiles";
import { IconContext } from "react-icons";
import FaIcons from "../../../assets/icons/faicons";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";

const SearchMember = ({ tennisSeason }) => {
  const [showAlert, setShowAlert] = useState(false); // Boolean to show the alert message
  const [memberNotFound, setMemberNotFound] = useState(false); // boolean to test whether the member is found or not
  const [showMember, setShowMember] = useState(false); // boolean to show member data
  const [csvFileData, setCsvFileData] = useState([]); // Array of objects of csv file data
  const [csvFileFields, setCsvFileFields] = useState([]); // array of list of csv file fields
  const [searchMember, setSearchMember] = useState(""); // state of member to search entered in input field
  const [memberFound, setMemberFound] = useState([]); // Array of object of unique member found
  const [membersFound, setMembersFound] = useState([]); // Array of objects of multiple members found
  const [listMembersFound, setListMembersFound] = useState([]); // Array of list of members found

  // Récupération du fichier CSV en fonction de l'année sélectionnée ===========
  useEffect(() => {
    const yearIndex = tabCsvFiles.findIndex(
      (item) => item.season === tennisSeason
    );
    const csvFile = tabCsvFiles[yearIndex].file;

    Papa.parse(csvFile, {
      download: true,
      header: true,
      dynamicTyping: true,
      delimiter: "",
      skipEmptyLines: true,
      complete: (result) => {
        const fields = result.meta.fields;
        const data = [];

        result.data.map((d) => data.push(d));

        setCsvFileFields(fields); // array of list of csv file fields
        setCsvFileData(data); // array of objects with member's data
      },
    });
  }, [tennisSeason]);

  // Récupération de la ou des fiches du nom de membre saisi =================
  useEffect(() => {
    let memberSearched = [];
    let newMemberSearched = [];

    if (searchMember) {
      memberSearched = csvFileData.filter(
        ({ name }) => name.toLocaleUpperCase() === searchMember
      );

      // replace all white space character in address and city names by non breaking space character
      // to avoid line breaks in respective cells of table displayed.
      newMemberSearched = memberSearched.map((object) => {
        return {
          ...object,
          // residence: object.residence.replaceAll(" ", "\u00a0"),
          street: object.street.replaceAll(" ", "\u00a0"), // \u00a0  refers to the Unicode character of &nbsp;
          city: object.city.replaceAll(" ", "\u00a0"),
        };
      });
    }

    if (memberSearched.length > 1) {
      const listOfMembersFound = [];
      newMemberSearched.map((d) => listOfMembersFound.push(Object.values(d)));
      setListMembersFound(listOfMembersFound);
      setMembersFound(newMemberSearched);
    } else {
      setMemberFound(newMemberSearched);
    }
  }, [searchMember, csvFileData]);

  // Function "onChange" ========================================================
  const handleSearchMember = (e) => {
    const memberName = e.target.value.toLocaleUpperCase();

    setShowMember(false);
    setSearchMember(memberName);
    setMemberFound([]);
    setMembersFound([]);
    setListMembersFound([]);
    setMemberNotFound(false);
  };
  // End of function "onChange" =================================================

  // Function "onKeyDown" === "Enter" ===========================================
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!searchMember) {
        setShowAlert(true);
      } else {
        const memberSearched = csvFileData.filter(
          ({ name }) => name.toLocaleUpperCase() === searchMember
        );

        if (memberSearched?.length > 0) {
          // setMemberFound([memberSearched[0]]);
          setMemberNotFound(false);
        } else {
          setMemberNotFound(true);
          setListMembersFound([]);
        }
      }
    }
  };
  // End of function "onClick" and "Enter" ======================================

  // Function "onClick" on the list of members ==================================
  const handleShowMember = (value) => (e) => {
    console.log("value: ", value);
    setShowMember(true);

    let memberSearched = [];

    memberSearched = membersFound.filter(({ surname }) => surname === value);

    setMemberFound(memberSearched);
  };
  // End of function "onClick" on the list of members ===========================

  return (
    <>
      <Card
        className="rounded-0 mb-4 border-0"
        style={{ backgroundColor: "rgb(50, 100, 150)" }}
      >
        <Card.Body className="p-1">
          <Form>
            <Row className="justify-content-center g-0">
              <Col xs="auto">
                <InputGroup>
                  <Form.Control
                    className="py-0 rounde-end-0"
                    type="text"
                    name="searchBar"
                    id="member"
                    placeholder="Nom adhérent"
                    onChange={handleSearchMember}
                    onKeyDown={handleKeyDown}
                  />
                  {/* <InputGroup.Text className="p-0">
                    <Button
                      variant="info"
                      // onClick={handleClickShowMember}
                      className="py-1 rounded-start-0"
                    >
                      <FaIcons.FcSearch />
                    </Button>
                  </InputGroup.Text> */}
                </InputGroup>
                {memberNotFound ? (
                  <Card.Text className="text-light mt-2 ps-2 fst-italic bg-danger">
                    L'adhérent saisi n'existe pas
                  </Card.Text>
                ) : null}
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {listMembersFound.length > 1 && (
        <Card bg="transparent" className="my-2 border-0 mc-rfs-5">
          <Card.Body>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th className="align-middle text-center text-light mc-rfs-4">
                    <FaIcons.FaEye />
                  </th>
                  {csvFileFields.map((row, i) => (
                    <th key={i}>{row}</th>
                  ))}
                </tr>
              </thead>

              <tbody className="bg-success">
                {listMembersFound.map((value, i) => (
                  <tr key={i}>
                    <td className="py-0 align-middle text-center">
                      <OverlayTrigger
                        placement={"top"}
                        overlay={
                          <Tooltip className="mc-rfs-7">
                            Ouvrir la fiche
                          </Tooltip>
                        }
                      >
                        <div
                          className="p-2 mc-rfs-4"
                          style={{ cursor: "pointer" }}
                          onClick={handleShowMember(value[3])}
                        >
                          <FaIcons.FaEye />
                        </div>
                      </OverlayTrigger>
                    </td>

                    {value.map((val, i) => {
                      return (
                        <td
                          key={i}
                          className={
                            i > value.length - 4
                              ? "py-0 align-middle text-center"
                              : "py-0 align-middle"
                          }
                        >
                          {val}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
      {memberFound.length === 1 || showMember ? (
        <Card className="m-3 border-0">
          <MemberSlip memberData={memberFound[0]} />
        </Card>
      ) : (
        ""
      )}

      {showAlert && (
        <Modal
          show={showAlert}
          centered
          onHide={() => setShowAlert(false)}
          contentClassName="flex-row bg-danger rounded border border-4 m-2 w-75"
        >
          <Modal.Header className="rounded-0 border-0">
            <Modal.Title className="text-light">
              <IconContext.Provider value={{ size: "2rem" }}>
                <FaIcons.FaExclamationTriangle />
              </IconContext.Provider>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-danger text-light fw-bold d-flex p-2 align-items-center">
            <p className="m-0 p-2">Aucun nom n'a été saisi !</p>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default SearchMember;
