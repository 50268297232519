import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import tabBureau from "../../../_params/tab-pages/leclub/tab-bureau";
import SideMenu from "../../../components/menus/m-side";
import { useNavHeight } from "../../../_utils/context/navheightprovider";

const LeBureau = () => {
  const { navbarHeight } = useNavHeight();

  return (
    <div className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="m-auto">
            <h1 className="my-3 mc-rampart mc-title text-center mc-rfs-1">
              Le Bureau
            </h1>
            <div className="mc-container-club">
              <Row>
                {tabBureau.map((card) => (
                  <Col xxl={4} xl={6} lg={9} key={card.id}>
                    <Card className="flex-row my-3 bg-light border border-success border-5 rounded-4">
                      <Col xs={4} md={4}>
                        <Card.Img
                          src={card.photoId}
                          alt="Photo Id?"
                          className="rounded-3"
                        />
                      </Col>
                      <Col xs={8} md={8}>
                        <Card.Body>
                          <Card.Title className="mc-rampart mc-rfs-3 text-primary">
                            {card.name}
                          </Card.Title>
                          <Card.Title className="text-primary mc-rfs-4 fw-bold">
                            {card.position}
                          </Card.Title>
                          <Card.Text className="mc-rfs-5 fst-italic fw-bold">
                            {card.commission}
                          </Card.Text>
                          <Card.Text className="mc-rfs-5">
                            {card.phone}
                          </Card.Text>
                        </Card.Body>
                      </Col>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LeBureau;
