import img20171 from "../../../assets/img/img-badminton/01-imgBadminton-2017-892x440.jpg";
import img20181 from "../../../assets/img/img-badminton/01-imgBadminton-2018-1282x711.jpg";
import img20182 from "../../../assets/img/img-badminton/02-imgBadminton-2018-4128x2322.jpg";
import img20183 from "../../../assets/img/img-badminton/03-imgBadminton-2018-4128x2322.jpg";
import img20184 from "../../../assets/img/img-badminton/04-imgBadminton-2018-481x647.jpg";
import img20185 from "../../../assets/img/img-badminton/05-imgBadminton-2018-778x294.jpg";
import img20191 from "../../../assets/img/img-badminton/01-imgBadminton-2019-1080x368.jpg";
import img20192 from "../../../assets/img/img-badminton/02-imgBadminton-2019-1080x810.jpg";
import img20193 from "../../../assets/img/img-badminton/03-imgBadminton-2019-1080x1440.jpg";
import img20194 from "../../../assets/img/img-badminton/04-imgBadminton-2019-1080x810.jpg";
import img20221 from "../../../assets/img/img-badminton/01-imgBadminton-2022-428x321.jpg";
import img20222 from "../../../assets/img/img-badminton/02-imgBadminton-2022-296x249.jpg";
import img20223 from "../../../assets/img/img-badminton/03-imgBadminton-2022-229x304.jpg";
import img20231 from "../../../assets/img/img-badminton/01-imgBadminton-2023-4000x1800.jpg";
import img20232 from "../../../assets/img/img-badminton/02-imgBadminton-2023-1080x810.jpg";
import img20233 from "../../../assets/img/img-badminton/03-imgBadminton-2023-1024x768.jpg";
import img20234 from "../../../assets/img/img-badminton/04-imgBadminton-2023-1080x810.jpg";
import img20235 from "../../../assets/img/img-badminton/05-imgBadminton-2023-1024x768.jpg";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabBadminton = [
  {
    id: 1,
    season: "2017",
    num: 0,
    date: "08/04/2017",
    comment: "",
    images: [
      {
        img: img20171,
        width: "892",
        height: "440",
      },
    ],
  },
  {
    id: 2,
    season: "2018",
    num: 28,
    date: "17/03/2018",
    comment: "",
    images: [
      { img: img20181, width: "1282", height: "711" },
      { img: img20182, width: "4128", height: "2322" },
      { img: img20183, width: "4128", height: "2322" },
      { img: img20184, width: "481", height: "647" },
      { img: img20185, width: "778", height: "294" },
    ],
  },
  {
    id: 3,
    season: "2019",
    num: 36,
    date: "23/03/2019",
    comment: "",
    images: [
      { img: img20191, width: "1080", height: "368" },
      { img: img20192, width: "1080", height: "810" },
      { img: img20193, width: "1080", height: "1440" },
      { img: img20194, width: "1080", height: "810" },
    ],
  },
  {
    id: 4,
    season: "2020",
    num: 0,
    date: "21/03/2020",
    comment: "Annulée suite aux restrictions COVID",
    images: [{}],
  },
  {
    id: 5,
    season: "2021",
    num: 0,
    date: "13/03/2021",
    comment: "Annulée suite aux restrictions COVID",
    images: [{}],
  },
  {
    id: 6,
    season: "2022",
    num: 39,
    date: "12/03/2022",
    comment: "",
    images: [
      { img: img20221, width: "428", height: "321" },
      { img: img20222, width: "296", height: "249" },
      { img: img20223, width: "229", height: "304" },
    ],
  },
  {
    id: 7,
    season: "2023",
    num: 43,
    date: "11/03/2023",
    comment: "",
    images: [
      { img: img20231, width: "4000", height: "1800" },
      { img: img20232, width: "1080", height: "810" },
      { img: img20233, width: "1024", height: "768" },
      { img: img20234, width: "1080", height: "810" },
      { img: img20235, width: "1024", height: "768" },
    ],
  },
];

const photosBadminton = tabBadminton.map((photo) => ({
  id: photo.id,
  season: photo.season,
  num: photo.num,
  date: photo.date,
  comment: photo.comment,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosBadminton;
