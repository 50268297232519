import { createContext, useContext, useState } from "react";

// Création du contexte de récupération de la hauteur des nav.
const NavHeightContext = createContext({
  navbarHeight: 0,
  sideNavHeight: 0,
  breadNavHeight: 0,
  updateNavbarHeight: () => {},
  updateSideNavHeight: () => {},
  updateBreadNavHeight: () => {},
});

export const useNavHeight = () => useContext(NavHeightContext);

export const NavHeightProvider = ({ children }) => {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [sideNavHeight, setSideNavHeight] = useState(0);
  const [breadNavHeight, setBreadNavHeight] = useState(0);

  // Valeurs de sauvegarde des hauteurs des barres de navigation qui varient en fonction de la taille de la police.
  const value = {
    navbarHeight,
    sideNavHeight,
    breadNavHeight,
    updateNavbarHeight: (newHeight) => setNavbarHeight(newHeight),
    updateSideNavHeight: (newHeight) => setSideNavHeight(newHeight),
    updateBreadNavHeight: (newHeight) => setBreadNavHeight(newHeight),
  };

  return (
    <NavHeightContext.Provider value={value}>
      {children}
    </NavHeightContext.Provider>
  );
};
