import * as yup from "yup";

const loginSchema = yup.object().shape({
  userIdAdmin: yup
    .string()
    .email("identifiant incorrect")
    .required("identifiant manquant"),

  pwdAdmin: yup.string().required("Mot de passe manquant"),
});

export default loginSchema;
