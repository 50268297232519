import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosEvents30Ans from "../../../_params/tab-pages/events/tab-3040ans";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";
import { useNavHeight } from "../../../_utils/context/navheightprovider";

const Events30Ans = () => {
  const { navbarHeight } = useNavHeight();

  const photosEvents30Ans2013 = [];

  //
  // Selection des photos en fonction de l'année
  //
  photosEvents30Ans.map((photo, index) => {
    if (photo.id === "2013") {
      photosEvents30Ans2013.push(photo);
    }
    return {
      photosEvents30Ans2013,
    };
  });

  return (
    <div className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              background: "rgba(0, 43, 110, 0.5)",
              zIndex: "1000",
            }}
          >
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumbEvents />
            {/************************** Partie Events30Ans */}
            <Card bg="primary" className="m-2 border-0">
              <Card.Header
                className="mc-rampart m-3 text-primary rounded-0 text-center bg-white"
                // style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
              >
                Le TCF Fête ses 30 ans
              </Card.Header>
              <Card.Text className="text-light text-center fw-bold">
                le 6 avril 2013
              </Card.Text>
            </Card>
            {/************************** Partie Events30Ans 2023 */}
            <Card bg="transparent" className="m-2 border-0">
              <Card.Body
                style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                className="m-2 p-4"
              >
                <PhotoAlbum layout="rows" photos={photosEvents30Ans2013} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Events30Ans;
