const tabInitProduits = [
  {
    id: 0,
    famille: "?",
    produits: [
      {
        produitId: 0,
        libelle: "?",
        unite: "?",
        prixUnitaire: 0,
      },
    ],
  },
];

export default tabInitProduits;
