import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";
import { motion } from "framer-motion";
import imgEvents from "../../../assets/img/images-events";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

const buttonVariants = {
  hover: {
    // scale: [1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1],
    scale: 1.3,
    textShadow: "0 0 8px rgb(255, 255, 255)",
    transition: { duration: 0.5, repeat: Infinity },
  },
};

const Events = () => {
  return (
    <div className="mc-fond-ecran">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col sm={6} md={4}>
            <Card className="p-2 m-2 text-light">
              <Card.Img src={imgEvents[0]} alt={""} />
              <Card.ImgOverlay>
                <Card.Title className="fw-bold">Fête des 30 ans</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  Le club a fêté ses 30 ans ...
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    to="/events/30ans"
                    className="px-3 p-1 border border-2 border-muted bg-warning rounded text-center nav-link"
                  >
                    <motion.div
                      className="text-dark fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={6} md={4}>
            <Card className="p-2 m-2 text-light">
              <Card.Img src={imgEvents[1]} alt={""} />

              <Card.ImgOverlay>
                <Card.Title className="fw-bold">Fête des 40 ans</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  Le club a fêté ses 40 ans ...
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    className="p-1 px-3 border border-2 border-muted bg-primary rounded text-center nav-link"
                    to="/events/40ans"
                  >
                    <motion.div
                      className="text-light fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={6} md={4}>
            <Card className="p-2 m-2 mc-rfs-5 text-light">
              <Card.Img src={imgEvents[2]} alt={""} />

              <Card.ImgOverlay>
                <Card.Title className="fw-bold">Jumelage</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  Rencontres avec nos amis du TC Bad Waldsee
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    className="p-1 px-3 border border-2 border-muted bg-success rounded text-center nav-link"
                    to="/events/jumelage"
                  >
                    <motion.div
                      className="text-light fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Events;
