import React from "react";
import Table from "react-bootstrap/Table";

const PlayersList = ({ teamsPlayers }) => {
  return (
    <div className="mc-players-bg">
      <div className="mc-players">
        <div id="mc-player">
          <Table responsive="sm">
            <thead>
              <tr className="fst-italic mc-rfs-5">
                <th className="text-warning bg-transparent">
                  Nom&nbsp;&nbsp;&nbsp;Prénom
                </th>
                <th className="text-warning bg-transparent">Clt</th>
                <th className="text-warning bg-transparent">N° de licence</th>
              </tr>
            </thead>

            {teamsPlayers.map((player, index) => (
              <tbody key={index}>
                <tr className="mc-rfs-6">
                  <td className="text-light bg-transparent">
                    {player.playerName}
                  </td>
                  <td className="text-light bg-transparent">
                    {player.ranking}
                  </td>
                  <td className="text-light bg-transparent">
                    {player.licenceNum}
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default PlayersList;
