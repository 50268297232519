const tableWeather = [
  {
    id: 200,
    main: "Thunderstorm",
    desc: "Orages avec légère pluie",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 201,
    main: "Thunderstorm",
    desc: "Orages et pluie",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 202,
    main: "Thunderstorm",
    desc: "Orages avec fortes pluies",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 210,
    main: "Thunderstorm",
    desc: "Orages légers",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 211,
    main: "Thunderstorm",
    desc: "Orages",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 212,
    main: "Thunderstorm",
    desc: "Orages violents",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 221,
    main: "Thunderstorm",
    desc: "Orages irréguliers",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 230,
    main: "Thunderstorm",
    desc: "Orages avec de légères bruines",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 231,
    main: "Thunderstorm",
    desc: "Orages avec bruines",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 232,
    main: "Thunderstorm",
    desc: "Orages avec de fortes bruines",
    icon: "icons/thunderstorms.svg",
  },
  {
    id: 300,
    main: "Drizzle",
    desc: "Légères bruines",
    icon: "icons/drizzle.svg",
  },
  {
    id: 301,
    main: "Drizzle",
    desc: "Bruines",
    icon: "icons/drizzle.svg",
  },
  {
    id: 302,
    main: "Drizzle",
    desc: "Fortes bruines",
    icon: "icons/drizzle.svg",
  },
  {
    id: 310,
    main: "Drizzle",
    desc: "Légères bruines et pluie",
    icon: "icons/drizzle.svg",
  },
  {
    id: 311,
    main: "Drizzle",
    desc: "Bruines et pluie",
    icon: "icons/drizzle.svg",
  },
  {
    id: 312,
    main: "Drizzle",
    desc: "Fortes bruines et pluie",
    icon: "icons/drizzle.svg",
  },
  {
    id: 313,
    main: "Drizzle",
    desc: "Averses et bruines",
    icon: "icons/drizzle.svg",
  },
  {
    id: 314,
    main: "Drizzle",
    desc: "Fortes averses et bruines",
    icon: "icons/drizzle.svg",
  },
  {
    id: 321,
    main: "Drizzle",
    desc: "Averses et bruines",
    icon: "icons/drizzle.svg",
  },
  {
    id: 500,
    main: "Rain",
    desc: "Légères pluies",
    icon: "icons/rain.svg",
  },
  {
    id: 500,
    main: "Rain",
    desc: "Pluie",
    icon: "icons/rain.svg",
  },
  {
    id: 501,
    main: "Rain",
    desc: "Pluies modérées",
    icon: "icons/rain.svg",
  },
  {
    id: 502,
    main: "Rain",
    desc: "Fortes pluies",
    icon: "icons/rain.svg",
  },
  {
    id: 503,
    main: "Rain",
    desc: "Très fortes pluies",
    icon: "icons/rain.svg",
  },
  {
    id: 504,
    main: "Rain",
    desc: "Pluies extrêmes",
    icon: "icons/rain.svg",
  },
  {
    id: 511,
    main: "Rain",
    desc: "Pluie verglaçantes",
    icon: "icons/rain.svg",
  },
  {
    id: 520,
    main: "Rain",
    desc: "Légères averses",
    icon: "icons/rain.svg",
  },
  {
    id: 522,
    main: "Rain",
    desc: "Fortes averses",
    icon: "icons/rain.svg",
  },
  {
    id: 531,
    main: "Rain",
    desc: "Averses irrégulières",
    icon: "icons/rain.svg",
  },
  {
    id: 501,
    main: "Rain",
    desc: "Pluie modérée",
    icon: "icons/rain.svg",
  },
  {
    id: 600,
    main: "Snow",
    desc: "Légères chutes de neige",
    icon: "icons/snow.svg",
  },
  {
    id: 601,
    main: "Snow",
    desc: "Chutes de neige",
    icon: "icons/snow.svg",
  },
  {
    id: 602,
    main: "Snow",
    desc: "Fortes chutes de neige",
    icon: "icons/snow.svg",
  },
  {
    id: 611,
    main: "Snow",
    desc: "Neige fondue",
    icon: "icons/snow.svg",
  },
  {
    id: 612,
    main: "Snow",
    desc: "Légères chutes de neige fondue",
    icon: "icons/snow.svg",
  },
  {
    id: 613,
    main: "Snow",
    desc: "Averses de neige fondue",
    icon: "icons/snow.svg",
  },
  {
    id: 615,
    main: "Snow",
    desc: "Légères pluies et neige",
    icon: "icons/snow.svg",
  },
  {
    id: 616,
    main: "Snow",
    desc: "Pluie et neige",
    icon: "icons/snow.svg",
  },
  {
    id: 620,
    main: "Snow",
    desc: "Légères chutes de neige",
    icon: "icons/snow.svg",
  },
  {
    id: 621,
    main: "Snow",
    desc: "Chutes de neige",
    icon: "icons/snow.svg",
  },
  {
    id: 622,
    main: "Snow",
    desc: "Fortes chutes de neige",
    icon: "icons/snow.svg",
  },
  {
    id: 701,
    main: "Mist",
    desc: "Brume",
    icon: "icons/mist.svg",
  },
  {
    id: 711,
    main: "Smoke",
    desc: "Nuage de fumée",
    icon: "icons/smoke.svg",
  },
  {
    id: 721,
    main: "Haze",
    desc: "Nappe de brouillard",
    icon: "icons/haze.svg",
  },
  {
    id: 731,
    main: "Dust",
    desc: "Nuage de poussière",
    icon: "icons/dust.svg",
  },
  {
    id: 741,
    main: "Fog",
    desc: "Brouillard",
    icon: "icons/fog.svg",
  },
  {
    id: 751,
    main: "Sand",
    desc: "Nuage de sable",
    icon: "icons/dust.svg",
  },
  {
    id: 761,
    main: "Dust",
    desc: "Nuage de poussière",
    icon: "icons/dust.svg",
  },
  {
    id: 762,
    main: "Ash",
    desc: "Nuage de cendre",
    icon: "icons/dust.svg",
  },
  {
    id: 771,
    main: "Squall",
    desc: "Rafales de vent",
    icon: "icons/wind.svg",
  },
  {
    id: 781,
    main: "Tornado",
    desc: "Tornade",
    icon: "icons/tornado.svg",
  },
  {
    id: 800,
    main: "Clear",
    desc: "Soleil",
    icon: "icons/clear-day.svg",
  },
  {
    id: 801,
    main: "Few Clouds",
    desc: "Quelques nuages",
    icon: "icons/partly-cloudy-day.svg",
  },
  {
    id: 802,
    main: "Scattered Clouds",
    desc: "Nuages épars",
    icon: "icons/partly-cloudy-day.svg",
  },
  {
    id: 803,
    main: "Broken Clouds",
    desc: "Nuages fragmentés",
    icon: "icons/partly-cloudy-day.svg",
  },
  {
    id: 804,
    main: "Overcast Clouds",
    desc: "Couvert",
    icon: "icons/cloudy.svg",
  },
];

export default tableWeather;
