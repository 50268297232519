import img20131 from "../../../assets/img/img-3040ans/01-img30ans-2013-768x744.jpg";
import img20132 from "../../../assets/img/img-3040ans/02-img30ans-2013-1024x556.jpg";
import img20133 from "../../../assets/img/img-3040ans/03-img30ans-2013-1024x629.jpg";
import img20134 from "../../../assets/img/img-3040ans/04-img30ans-2013-2117x1065.jpg";
import img20135 from "../../../assets/img/img-3040ans/05-img30ans-2013-1955x1261.jpg";
import img20136 from "../../../assets/img/img-3040ans/06-img30ans-2013-2019x1360.jpg";
import img20137 from "../../../assets/img/img-3040ans/07-img30ans-2013-2299x1068.jpg";
import img20138 from "../../../assets/img/img-3040ans/08-img30ans-2013-1434x977.jpg";
import img20221 from "../../../assets/img/img-3040ans/01-img40ans-2022-1067x1205.jpg";
import img20222 from "../../../assets/img/img-3040ans/02-img40ans-2022-1449x1024.jpg";
import img20223 from "../../../assets/img/img-3040ans/03-img40ans-2022-1200x1600.jpg";
import img20224 from "../../../assets/img/img-3040ans/04-img40ans-2022-1600x720.jpg";
import img20225 from "../../../assets/img/img-3040ans/05-img40ans-2022-1600x1200.jpg";
import img20226 from "../../../assets/img/img-3040ans/06-img40ans-2022-1500x1000.jpg";
import img20227 from "../../../assets/img/img-3040ans/07-img40ans-2022-1600x720.jpg";
import img20228 from "../../../assets/img/img-3040ans/08-img40ans-2022-1500x1000.jpg";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tab3040ans = [
  { id: "2013", img: img20131, width: "768", height: "744" },
  { id: "2013", img: img20132, width: "1024", height: "556" },
  { id: "2013", img: img20133, width: "1024", height: "629" },
  { id: "2013", img: img20134, width: "2117", height: "1065" },
  { id: "2013", img: img20135, width: "1955", height: "1261" },
  { id: "2013", img: img20136, width: "2019", height: "1360" },
  { id: "2013", img: img20137, width: "2299", height: "1068" },
  { id: "2013", img: img20138, width: "1434", height: "977" },
  { id: "2022", img: img20221, width: "1067", height: "1205" },
  { id: "2022", img: img20222, width: "1449", height: "1024" },
  { id: "2022", img: img20223, width: "1200", height: "1600" },
  { id: "2022", img: img20224, width: "1600", height: "720" },
  { id: "2022", img: img20225, width: "1600", height: "1200" },
  { id: "2022", img: img20226, width: "1500", height: "1000" },
  { id: "2022", img: img20227, width: "1600", height: "720" },
  { id: "2022", img: img20228, width: "1500", height: "1000" },
];

const photos3040ans = tab3040ans.map((photo) => ({
  id: photo.id,
  src: photo.img,
  width: photo.width,
  height: photo.height,
  srcSet: breakpoints.map((breakpoint) => {
    const height = Math.round((photo.height / photo.width) * breakpoint);
    return {
      id: photo.id,
      src: photo.img,
      width: breakpoint,
      height,
    };
  }),
}));

export default photos3040ans;
