import tournoiInterne from "../../../assets/img/img-animations/04_tennis-raquettes.png";
import soireeClub from "../../../assets/img/img-animations/08_soireeClub.png";
import stage from "../../../assets/img/img-enseignement/stages0.jpg";
import parentsEnfants from "../../../assets/img/img-animations/10_parentsEnfants.jpg";
import tournoiOrange from "../../../assets/img/img-animations/11_tournoiJeunesOrange.jpg";
import tournoiVert from "../../../assets/img/img-animations/12_tournoiJeunesVert.jpg";

const tabActivitesJeunes = [
  {
    id: 0,
    image: parentsEnfants,
    animation: "Parents - Enfants",
    desc: "Rencontre parents enfants",
    dateDebut: "2023-10-21",
    dateFin: "2023-10-21",
    lieu: "Courts couverts 11h à 12h",
    link: "/animations",
  },
  {
    id: 1,
    image: parentsEnfants,
    animation: "Parents - Enfants",
    desc: "Rencontre parents enfants - groupes du mercredi 10h et jeudi 16h45",
    dateDebut: "2023-12-02",
    dateFin: "2023-12-02",
    lieu: "Courts couverts 11h à 12h",
    link: "/animations",
  },
  {
    id: 2,
    image: tournoiInterne,
    animation: "Tournoi interne",
    desc: "Tournoi interne balles oranges - groupes du mercredi 10h et jeudi 16h45",
    dateDebut: "2023-12-16",
    dateFin: "2023-12-16",
    lieu: "Courts couverts de 10h à 13h",
    link: "/competitions",
  },
  {
    id: 3,
    image: parentsEnfants,
    animation: "Parents - Enfants",
    desc: "Rencontres parents enfants - groupes du mercredi 10h et jeudi 16h45",
    dateDebut: "2024-02-10",
    dateFin: "2024-02-10",
    lieu: "Courts couverts 11h à 12h30",
    link: "/animations",
  },
  {
    id: 4,
    image: stage,
    animation: "Stages Hiver",
    desc: "Semaine de stage sur une demi-journée",
    dateDebut: "2024-02-26",
    dateFin: "2024-03-01",
    lieu: "Courts couverts",
    link: "/enseignement#stageTennis",
  },
  {
    id: 5,
    image: tournoiInterne,
    animation: "Tournoi interne",
    desc: "Tournoi interne balles rouges - groupes du mercredi 10h et jeudi 16h45",
    dateDebut: "2024-04-06",
    dateFin: "2024-04-06",
    lieu: "Courts couverts de 10h à 13h",
    link: "/competitions",
  },
  {
    id: 6,
    image: stage,
    animation: "Stages Pâques",
    desc: "Semaine de stage tennis et multisports sur la journée entière",
    dateDebut: "2024-04-15",
    dateFin: "2024-04-19",
    lieu: "Courts couverts",
    link: "/enseignement#stageTennis",
  },
  {
    id: 7,
    image: parentsEnfants,
    animation: "Parents - Enfants",
    desc: "Rencontres parents enfants suivi d'un repas à 20h - groupes du mercredi 10h et jeudi 16h45",
    dateDebut: "2024-05-31",
    dateFin: "2024-05-31",
    lieu: "Courts couverts 18h à 20h",
    link: "/animations",
  },
  {
    id: 8,
    image: parentsEnfants,
    animation: "Parents - Enfants",
    desc: "Rencontres parents enfants",
    dateDebut: "2024-06-13",
    dateFin: "2024-06-13",
    lieu: "Courts extérieurss à 20h",
    link: "/animations",
  },
  {
    id: 9,
    image: tournoiInterne,
    animation: "Tournoi interne",
    desc: "Tournoi interne de fin de saison - Groupe compétition du mercredi et du samedi matin",
    dateDebut: "2024-06-14",
    dateFin: "2024-06-14",
    lieu: "Courts extérieurss de 17h à 20h",
    link: "/enseignements",
  },
  {
    id: 10,
    image: soireeClub,
    animation: "Fête de l’école de Tennis",
    desc: "Jeux et démonstrations des jeunes de l'école de tennis ...",
    dateDebut: "2024-06-19",
    dateFin: "2024-06-19",
    lieu: "Courts extérieurs de 14h à 17h",
    link: "/animations/soireeclub",
  },
  {
    id: 11,
    image: stage,
    animation: "Stages d'été",
    desc: "Stage tennis et multisports sur toute la journée",
    dateDebut: "2024-07-08",
    dateFin: "2024-07-12",
    lieu: "Courts extérieurs",
    link: "/enseignement#stageTennis",
  },
  {
    id: 12,
    image: stage,
    animation: "Stages d'été",
    desc: "Stage tennis et multisports sur toute la journée",
    dateDebut: "2024-07-15",
    dateFin: "2024-07-19",
    lieu: "Courts extérieurs",
    link: "/enseignement#stageTennis",
  },
  {
    id: 13,
    image: stage,
    animation: "Stages d'été",
    desc: "Stage tennis et multisports sur toute la journée",
    dateDebut: "2024-07-22",
    dateFin: "2024-07-26",
    lieu: "Courts extérieurs et couverts",
    link: "/enseignement#stageTennis",
  },
  {
    id: 14,
    image: tournoiOrange,
    animation: "Tournoi jeunes",
    desc: "Tournoi Galaxie Orange",
    dateDebut: "2024-08-24",
    dateFin: "2024-08-24",
    lieu: "Courts extérieurs",
    link: "/competitions",
  },
  {
    id: 15,
    image: tournoiVert,
    animation: "Tournoi jeunes",
    desc: "Tournoi Galaxie Vert",
    dateDebut: "2024-08-25",
    dateFin: "2024-08-25",
    lieu: "Courts extérieurs",
    link: "/competitions",
  },
  {
    id: 16,
    image: tournoiInterne,
    animation: "Tournoi jeunes",
    desc: "Galaxie tableaux 11/12-13/14-15/16 ans",
    dateDebut: "2024-08-26",
    dateFin: "2024-09-01",
    lieu: "Courts extérieurs",
    link: "/competitions",
  },
];

export default tabActivitesJeunes;
