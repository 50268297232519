import React from "react";
import imgPartners from "../../../assets/img/images-partners";

export const Partners = () => {
  const listimgpartners = imgPartners.map((image) => (
    <div key={image.id}>
      <a href={image.link} target="_blank" rel="noreferrer">
        <img className="mc-logos-img" src={image.img} alt="" />
      </a>
    </div>
  ));

  return (
    <div className="mc-neon">
      <div className="mc-logos">
        <div className="mc-logos-slide">{listimgpartners}</div>
        <div className="mc-logos-slide">{listimgpartners}</div>
      </div>
    </div>
  );
};

export default Partners;
