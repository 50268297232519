import Fred from "../../../assets/img/img-bureau/idFred.jpg";
import Galaxie from "../../../assets/img/img-enseignement/galaxie.jpg";
import CriteresGalaxie from "../../../assets/img/img-enseignement/criteresGalaxie.png";
import AfficheGalaxie from "../../../assets/img/img-enseignement/afficheGalaxieTennis.jpg";
import Ados from "../../../assets/img/img-enseignement/ados.jpg";
import Adulte1 from "../../../assets/img/img-enseignement/tennisadulte1.jpg";
import Adulte2 from "../../../assets/img/img-enseignement/tennisadulte2.jpg";
import StageP1 from "../../../assets/img/img-enseignement/stages0.jpg";
import StageP2 from "../../../assets/img/img-enseignement/stages1.jpg";
import Label from "../../../assets/img/img-enseignement/tennisSanteLabel.png";
import TennisSante from "../../../assets/img/img-enseignement/tennisSante.jpeg";
import TennisSanteAffiche from "../../../assets/img/img-enseignement/tennisSanteAffiche.jpeg";
import TennisSanteArticle from "../../../assets/img/img-enseignement/tennisSanteArticle.jpg";

const tabEnseignement = [
  { id: "Fred", image: Fred },
  { id: "Galaxie", image: Galaxie },
  { id: "CriteresGalaxie", image: CriteresGalaxie },
  { id: "AfficheGalaxie", image: AfficheGalaxie },
  { id: "Ados", image: Ados },
  { id: "Adulte1", image: Adulte1 },
  { id: "Adulte2", image: Adulte2 },
  { id: "StageP1", image: StageP1 },
  { id: "StageP2", image: StageP2 },
  { id: "Label", image: Label },
  { id: "TennisSante", image: TennisSante },
  { id: "TennisSanteAffiche", image: TennisSanteAffiche },
  { id: "TennisSanteArticle", image: TennisSanteArticle },
];

export default tabEnseignement;
