import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import SideMenu from "../../components/menus/m-side";
import { useNavHeight } from "../../_utils/context/navheightprovider";
import tableSaisons from "../../_params/tab-config/tab-saisons";
import tabCsvFiles from "../../_params/tab-pages/members/tab-csvFiles";
import SearchMember from "../../components/pages/admin/searchmember";

const FicheMembre = () => {
  const { navbarHeight } = useNavHeight();
  const [currentSeason, setCurrentSeason] = useState(0);

  useEffect(() => {
    let year = 0;

    /*************************** Selection of today's date to select current season year */
    const todaysDate = new Date();
    for (let i = 0; i < tableSaisons.length; i++) {
      const startDate = Date.parse(tableSaisons[i].startDate);
      const endDate = Date.parse(tableSaisons[i].endDate);

      if (todaysDate > startDate && todaysDate < endDate) {
        year = tableSaisons[i].season;
        break;
      }
    }
    setCurrentSeason(year);
  }, []);

  //
  /* Récupération des saisons présentes dans le fichier des membres */
  //
  const tabSaison = [];
  let season = "";

  tabCsvFiles.map((saison) => {
    if (saison.season !== season) {
      season = saison.season;

      tabSaison.push({ season: saison.season });
    }
    return {
      tabSaison,
    };
  });
  /* FIN - Récupération des saisons existant dans la table des fichiers des membres */

  const handleSeasonChange = (e) => {
    const season = e.target.value;
    setCurrentSeason(season);
  };

  return (
    <div className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            xs={12}
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>

          <Col xs={12} md={9} className="g-0">
            <Row className="justify-content-center align-items-center text-center text-primary bg-light m-4 p-2 rounded mc-rampart">
              <Col md={9} className="g-0">
                <h1 className="m-0 mc-rfs-1">FICHE ADHÉRENT</h1>
              </Col>
              <Col md={3} className="g-0">
                <Form.Select
                  className="mc-rfs-3 m-auto text-center text-primary bg-secondary-subtle"
                  name="currentSeason"
                  value={currentSeason}
                  onChange={handleSeasonChange}
                >
                  {tabSaison.map((saison, i) => {
                    return <option key={i}>{saison.season}</option>;
                  })}
                </Form.Select>
              </Col>
            </Row>
            {currentSeason && <SearchMember tennisSeason={currentSeason} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FicheMembre;
