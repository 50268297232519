import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Document, Page, pdfjs } from "react-pdf";
import { useComponentSize } from "react-use-size";
import {
  FaStepBackward,
  FaChevronRight,
  FaChevronLeft,
  FaStepForward,
  FaDownload,
} from "react-icons/fa";
import { motion } from "framer-motion";

const PdfViewer = ({ pdfFile }) => {
  // loading the pre-packaged version of pdfjs to initialize a worker in the browser.
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { ref, width } = useComponentSize();

  const pdfVariants = {
    none: "",
    hover: {
      cursor: "zoom-in",
      scale: 0.95,
      transition: { duration: 0.5 },
    },
    tap: {
      translateX: "200px",
      scale: 1.5,
      transition: { duration: 0.5 },
    },
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const goToFirstPage = () => {
    setPageNumber(1);
  };

  const goToLastPage = () => {
    setPageNumber(numPages);
  };
  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <div ref={ref}>
      <div className="d-flex justify-content-between">
        <nav>
          {/******* GO TO FIRST PAGE ***************/}
          <OverlayTrigger placement={"top"} overlay={<Tooltip>début</Tooltip>}>
            <Button
              variant="white"
              role="button"
              disabled={pageNumber === 1}
              onClick={goToFirstPage}
              className="border border-1 border-secondary bg-secondary bg-gradient text-light border-light m-1 p-1 px-2"
            >
              <FaStepBackward />
            </Button>
          </OverlayTrigger>
          {/******* GO TO PREVIOUS PAGE **********/}
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>page préc.</Tooltip>}
          >
            <Button
              variant="white"
              role="button"
              disabled={pageNumber === 1}
              onClick={goToPrevPage}
              className="border border-1 border-secondary bg-secondary bg-gradient text-light border-light m-1 p-1 px-2"
            >
              <FaChevronLeft />
            </Button>
          </OverlayTrigger>
          {/******* GO TO NEXT PAGE **************/}
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>page suiv.</Tooltip>}
          >
            <Button
              variant="white"
              role="button"
              disabled={pageNumber === numPages}
              onClick={goToNextPage}
              className="border border-1 border-secondary bg-secondary bg-gradient text-light border-light m-1 p-1 px-2"
            >
              <FaChevronRight />
            </Button>
          </OverlayTrigger>
          {/******* GO TO LAST PAGE **************/}
          <OverlayTrigger placement={"top"} overlay={<Tooltip>fin</Tooltip>}>
            <Button
              variant="white"
              role="button"
              disabled={pageNumber === numPages}
              onClick={goToLastPage}
              className="border border-1 border-secondary bg-secondary bg-gradient text-light border-light m-1 p-1 px-2"
            >
              <FaStepForward />
            </Button>
          </OverlayTrigger>
        </nav>
        {/******* Télécharger le bon de commande **************/}
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip>Télécharger le bon de commande</Tooltip>}
        >
          <a
            href={pdfFile}
            download="TCF-Bon-de-Commande-Fromage-Vin-PDF"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              variant="white"
              role="button"
              className="border border-1 border-secondary bg-secondary bg-gradient text-light border-light m-1 p-1 px-2"
            >
              <FaDownload />
            </Button>
          </a>
        </OverlayTrigger>

        <p className="pt-2" style={{ fontSize: "0.8rem" }}>
          Page {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
        </p>
      </div>
      <motion.div
        variants={pdfVariants}
        whileHover={"hover"}
        whileTap={"tap"}
        style={{ position: "relative", zIndex: "200" }}
      >
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          error="Erreur de chargement du fichier PDF."
          onLoadError={() => {
            console.log("CALLED");
          }}
          loading="Chargement du fichier PDF…"
          onLoadProgress={() => {
            console.log("PROGRESS");
          }}
        >
          <Page
            className={"border border-1 border-warning me-2"}
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={width}
          />
        </Document>
      </motion.div>
    </div>
  );
};

export default PdfViewer;
