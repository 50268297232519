import imgnews0 from "../../../assets/img/img-news/newBadge-450x300.png";
import imgnews1 from "../../../assets/img/img-news/inscriptions-450x300.png";
// import imgnews2 from "../../../assets/img/img-news/stages2024-450x300.png";
import imgnews3 from "../../../assets/img/img-news/repriseCours-450x300.png";
// import imgnews4 from "../../../assets/img/img-news/retardataire-450x300.png";
// import imgnews5 from "../../../assets/img/img-news/ag-2023-tcf-450x300.png";
import imgnews6 from "../../../assets/img/img-news/imgPickleball-450x300.png";
// import imgnews7 from "../../../assets/img/img-news/inscriptionTournoi-450x300.png";

const tableNews = [
  {
    id: 0,
    img: imgnews0,
    title: "Carte d'accès courts extérieurs 2024",
    texte:
      "La carte a changé. Pour obtenir la nouvelle clé, il suffit de rapporter l'ancienne aux membres du bureau ...",
  },
  {
    id: 1,
    img: imgnews1,
    title: "Adhésion au club 2025",
    texte:
      "Les inscriptions se dérouleront samedi 31/8 de 10h à 12h et 14h à 18h et samedi 7/9 de 10h à 12h durant le forum des assocos. Venez nombreux ...",
  },
  // {
  //   id: 2,
  //   img: imgnews2,
  //   title: "Inscriptions aux stages multi-activités",
  //   texte:
  //     "Les inscriptions aux stages d'été sont ouvertes. Pour vous inscrire, contactez directement notre moniteur au 06 60 61 21 53 ...",
  // },
  {
    id: 2,
    img: imgnews3,
    title: "Reprise des cours 2025",
    texte: "Les cours de tennis reprendront la semaine du 16 septembre ...",
  },
  // {
  //   id: 4,
  //   img: imgnews4,
  //   title: "Licences d'été 2024",
  //   texte:
  //     "Les adhésions pour bénéficier des courts de tennis durant l'été (juin, juillet, août) sont ouvertes => Tarif Adulte : 55 €. CONTACTEZ-NOUS !",
  // },
  // {
  //   id: 5,
  //   img: imgnews5,
  //   title: "Assemblée Générale 2023",
  //   texte: "Samedi 21/10/2023 à 18h au clubhouse.",
  // },
  {
    id: 3,
    img: imgnews6,
    title: "PICKLEBALL 2025",
    texte:
      "NOUVELLE ACTIVITÉ : Une section Pickleball s'ouvre au TC Feillens à partir de septembre 2024. RENSEIGNEZ-VOUS ...",
  },
];

export default tableNews;
