import * as yup from "yup";

const commandesSchema = yup.object().shape({
  userName: yup
    .string()
    .min(5, "5 caractères mini sont requis")
    .required("Le nom est nécessaire"),
  userTel: yup
    .string()
    .min(10, "10 car mini")
    .max(15, "15 car maxi")
    .required("Le numéro de tél est nécessaire"),
  userMail: yup
    .string()
    .email("Entrer une adresse valide")
    .required("L'adresse email est nécessaire"),
});

export default commandesSchema;
