import badminton from "./img-animations/01_badminton.png";
import venteFromageVin from "./img-animations/02_venteFromageVin.png";
import championnats from "./img-animations/03_tennis-court.png";
import tournoiInterne from "./img-animations/04_tennis-raquettes.png";
import programme from "./img-animations/05_programme.png";
import pickleball from "./img-animations/06_pickleball.png";
import ecoleTennis from "./img-animations/07_ecoleTennis.png";
import soireeClub from "./img-animations/08_soireeClub.png";

const imgAnim = [
  badminton,
  venteFromageVin,
  championnats,
  tournoiInterne,
  programme,
  pickleball,
  ecoleTennis,
  soireeClub,
];

export default imgAnim;
