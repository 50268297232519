const commandesInitValues = {
  userName: "",
  userTel: "",
  userMail: "",
  userTotalCde: 0,
  userFamilles: [
    {
      userFamId: null,
      userFamLibelle: "",
      userFamTotalFamille: 0,
      userFamProduits: [
        {
          userProdId: null,
          userProdLibelle: "",
          userProdUnite: "",
          userProdPU: 0,
          userProdQty: 0,
          userProdTotalProd: 0,
        },
      ],
    },
  ],
};

export default commandesInitValues;
