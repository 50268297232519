import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const login = async (data) => {
    setIsAuthenticated(data);
    navigate("/admin", { replace: true });
  };

  /************************************************ LOGOUT Function **************/
  const logout = () => {
    setIsAuthenticated(false);
    navigate("/", { replace: true });
  };
  /************************************************ END LOGOUT Function ***********/

  const value = {
    isAuthenticated,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
