import { Nav } from "react-bootstrap";
import Logout from "../../assets/icons/icons8-close-94.png";
import Image from "react-bootstrap/Image";
import { useAuth } from "../context/authprovider";
import { useNavigate } from "react-router-dom";

const BtnExitAdmin = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleClick = () => {
    logout(() => navigate("/"));
  };

  return (
    <Nav
      className="position-fixed bottom-0"
      style={{ zIndex: 1000, left: "15vw" }}
    >
      <Nav.Item className="m-3">
        <Nav.Link
          href="/"
          className="mc-top-page p-0 border border-4 border-danger rounded-5"
          onClick={handleClick}
        >
          <div
            className=""
            style={{
              height: "35px",
              width: "35px",
            }}
          >
            <Image
              className="bg-danger-subtle rounded-5"
              src={Logout}
              thumbnail
            />
          </div>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default BtnExitAdmin;
