import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const MemberSlip = ({ memberData }) => {
  return (
    <Container fluid className="gx-0">
      <Row className="gx-0">
        <Card
          className="border-0 p-3"
          style={{
            backgroundColor:
              memberData.gender === "F"
                ? "rgb(150, 53, 220"
                : "rgb(100, 100, 220",
          }}
        >
          <Card className="my-2 text-dark text-center">
            <h3 className="fw-bold m-0">
              {memberData.surname} {memberData.name}
            </h3>
          </Card>

          <Card className="my-2 text-dark">
            <Card.Header className="ps-2 py-0 fst-italic bg-secondary-subtle fw-bold">
              Informations générales
            </Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={3}>
                  <b>Civilité</b>
                  <p className="mb-0">
                    {memberData.gender === "F" ? "Mme" : "Mr"}
                  </p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Nationalité</b>
                  <p className="mb-0">
                    {memberData.nationality === "Française" && "FR"}
                  </p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Date de Naissance</b>
                  <p className="mb-0">{memberData.birthdate}</p>
                </Col>
                <Col xs={6} md={2}>
                  <b>Catégorie</b>
                  <p className="mb-0">{memberData.category} ans</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="my-2 text-dark">
            <Card.Header className="ps-2 py-0 fst-italic bg-secondary-subtle fw-bold">
              Adhésion
            </Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={3}>
                  <b>Période</b>
                  <p className="mb-0">{memberData.period}</p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Type</b>
                  <p className="mb-0">
                    {memberData.type === "Cours"
                      ? "Adhésion + cours"
                      : "Adhésion"}
                  </p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Nb heures / semaine</b>
                  <p className="mb-0">
                    {memberData.secHour === "X" ? "2 heures" : "1 heure"}
                  </p>
                </Col>
                <Col xs={6} md={3}>
                  <b>N° de licence</b>
                  <p className="mb-0">
                    {memberData.licence} {memberData.l}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="my-2 text-dark">
            <Card.Header className="ps-2 py-0 fst-italic bg-secondary-subtle fw-bold">
              Adresse
            </Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={3}>
                  <b>Résidence, bât, esc.</b>
                  <p className="mb-0">{memberData.residence}</p>
                </Col>
                <Col xs={6} md={4}>
                  <b>N° de voie</b>
                  <p className="mb-0">{memberData.street}</p>
                </Col>
                <Col xs={6} md={2}>
                  <b>CP</b>
                  <p className="mb-0">{memberData.zip}</p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Ville</b>
                  <p className="mb-0">{memberData.city}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="my-2 text-dark">
            <Card.Header className="ps-2 py-0 fst-italic bg-secondary-subtle fw-bold">
              Contact
            </Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={3}>
                  <b>Tél domicile</b>
                  <p className="mb-0">{memberData.homeTel}</p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Tél Portable</b>
                  <p className="mb-0">{memberData.persoTel}</p>
                </Col>
                <Col xs={6} md={6}>
                  <b>mail</b>
                  <p className="mb-0">{memberData.email}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="my-2 text-dark">
            <Card.Header className="ps-2 py-0 fst-italic bg-secondary-subtle fw-bold">
              Accès courts
            </Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={6}>
                  <b>Clé courts extérieurs</b>
                  <p className="mb-0">
                    {memberData.keyExt === "X" ? "OUI" : "NON"}
                  </p>
                </Col>
                <Col xs={6} md={6}>
                  <b>Badge courts couverts</b>
                  <p className="mb-0">
                    {memberData.badgeInt === "X" ? "OUI" : "NON"}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Card>
      </Row>
    </Container>
  );
};

export default MemberSlip;
