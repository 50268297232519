import idMichel from "../../../assets/img/img-bureau/idMichel.jpg";
import idYvan from "../../../assets/img/img-bureau/idYvan.jpg";
import idJeremie from "../../../assets/img/img-bureau/idJeremie.jpg";
import idShauna from "../../../assets/img/img-bureau/idShauna.jpg";
import idLaurence from "../../../assets/img/img-bureau/idLaurence.jpg";
import idMagaly from "../../../assets/img/img-bureau/idMagaly.jpg";
import idFX from "../../../assets/img/img-bureau/idFX.jpg";

const tabBureau = [
  {
    id: 0,
    name: "Michel CEDDIA",
    position: "Président",
    phone: "06.82.94.80.94",
    commission: "Toutes",
    photoId: idMichel,
    userId: "ceddiam@aol.com",
  },
  {
    id: 1,
    name: "Yvan BESSON",
    position: "Vice-Président",
    phone: "06.17.91.26.36",
    commission: "Toutes",
    photoId: idYvan,
    userId: "yvan.besson@wanadoo.fr",
  },
  {
    id: 2,
    name: "Jérémie CATHERIN",
    position: "Trésorier Général",
    phone: "06.21.78.60.43",
    commission: "Toutes",
    photoId: idJeremie,
    userId: "jeremie.catherin@gmail.com",
  },
  {
    id: 3,
    name: "Shauna FARAONE",
    position: "Secrétaire Générale",
    phone: "06.59.52.03.88",
    commission: "Commission Animations",
    photoId: idShauna,
    userId: "shaunaf@hotmail.fr",
  },
  {
    id: 4,
    name: "Laurence CEDDIA",
    position: "Trésorière Adjointe",
    phone: "06.59.77.38.69",
    commission: "Commission Tennis Féminin",
    photoId: idLaurence,
    userId: "laurenceceddia@aol.com",
  },
  {
    id: 5,
    name: "Magaly LAMPIS",
    position: "Membre",
    phone: "06.60.84.73.95",
    commission: "Commission Animations",
    photoId: idMagaly,
    userId: "maggyfx@gmail.com",
  },
  {
    id: 6,
    name: "François-Xavier GATINET",
    position: "Membre",
    phone: "06.60.85.07.50",
    commission: "Commission Equipement, Commission Sportive",
    photoId: idFX,
    userId: "fxparisien71@hotmail.fr",
  },
];

export default tabBureau;
