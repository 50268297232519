const resetTable = (table) => {
  const resetTable = table.map((famille) => {
    famille.produits.map((produit) => {
      produit.qty = 0;
      produit.totalLine = 0;
      return produit;
    });
    return famille;
  });
  return resetTable;
};

export default resetTable;
