import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import BtnReset from "../../../_utils/tools/btn-reset";

const DetailTarifs = ({
  handleChange,
  handleBlur,
  handleReset,
  values,
  touched,
  errors,
}) => {
  return (
    <Container fluid className="gx-0">
      <Row className="mx-2">
        <Card className="my-3 p-0 text-center border border-5 border-dark-subtle rounded bg-warning flex-row justify-content-around align-items-center">
          <Card.Title className="mc-rfs-3 m-0 p-2 text-dark fw-bold">
            Détail de l'offre
          </Card.Title>
          <Card.Text className="p-1">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">Remise à zéro</Tooltip>}
            >
              {BtnReset(handleReset)}
            </OverlayTrigger>
          </Card.Text>
        </Card>

        {/* Choix du type de licence *****************************************************************/}
        <Col sm={6} md={12} className="p-0">
          <Card className="m-1">
            <Card.Body className="p-2">
              <Card.Title className="mc-rfs-4 fw-bold">
                Type de licence
              </Card.Title>
              <Row className="m-0">
                {["Multi-raquettes", "Découverte", "Scolaire"].map(
                  (licence) => (
                    <Col md={4} key={licence} className="p-0">
                      <Form.Check
                        className="mc-rfs-5 mx-1"
                        type="radio"
                        label={licence}
                        name="licence"
                        value={licence}
                        checked={values.licence === licence}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.licence && !errors.licence}
                        isInvalid={touched.licence && !!errors.licence}
                      />
                    </Col>
                  )
                )}
                {!errors.licence ? null : (
                  <Form.Control.Feedback
                    type="invalid"
                    className="mc-rfs-6 d-block fst-italic bg-danger lh-1 text-light p-1 ps-2 rounded"
                  >
                    {errors.licence}
                  </Form.Control.Feedback>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Choix du type d'adhésion *****************************************************************/}
        <Col sm={6} md={12} className="p-0">
          <Card className="m-1">
            <Card.Body className="p-2">
              <Card.Title className="mc-rfs-4 fw-bold">
                Type d'adhésion
              </Card.Title>
              <Row className="m-0">
                {["Adhésion seule", "Adhésion + cours"].map((adhesion) => (
                  <Col md={6} key={adhesion} className="p-0">
                    <Form.Check
                      className="mc-rfs-5 mx-1"
                      type="radio"
                      label={adhesion}
                      name="membership"
                      value={adhesion}
                      checked={values.membership === adhesion}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.membership && !errors.membership}
                      isInvalid={touched.membership && !!errors.membership}
                    />
                  </Col>
                ))}
                {!errors.membership ? null : (
                  <Form.Control.Feedback
                    type="invalid"
                    className="mc-rfs-6 d-block fst-italic bg-danger lh-1 text-light p-1 ps-2 rounded"
                  >
                    {errors.membership}
                  </Form.Control.Feedback>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>

        {/* Choix du nb d'heures de cours ************************************************************/}
        {values.membership === "Adhésion + cours" ? (
          <Col sm={6} md={12} className="p-0">
            <Card className="m-1">
              <Card.Body className="p-2">
                <Card.Title className="mc-rfs-4 fw-bold">
                  Heures de cours (+ 155 €/h)
                </Card.Title>
                <Row className="m-0">
                  {["1 heure", "2 heures", "3 heures"].map((hours) => (
                    <Col md={4} key={hours} className="p-0">
                      <Form.Check
                        className="mc-rfs-5 mx-1"
                        type="radio"
                        label={hours}
                        name="membershipNumHours"
                        value={
                          hours === "1 heure"
                            ? "1"
                            : hours === "2 heures"
                            ? "2"
                            : "3"
                        }
                        checked={
                          values.membershipNumHours ===
                          (hours === "1 heure"
                            ? "1"
                            : hours === "2 heures"
                            ? "2"
                            : "3")
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.membershipNumHours &&
                          !errors.membershipNumHours
                        }
                        isInvalid={
                          touched.membershipNumHours &&
                          !!errors.membershipNumHours
                        }
                      />
                    </Col>
                  ))}
                  {!errors.membershipNumHours ? null : (
                    <Form.Control.Feedback
                      type="invalid"
                      className="mc-rfs-6 d-block fst-italic bg-danger lh-1 text-light ms-2 p-1 ps-2 rounded"
                    >
                      {errors.membershipNumHours}
                    </Form.Control.Feedback>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <div className="d-none">{(values.membershipNumHours = "")}</div>
        )}

        {/* Choix de la période d'adhésion ***********************************************************/}
        <Col sm={6} md={12} className="p-0">
          <Card className="m-1">
            <Card.Body className="p-2">
              <Card.Title className="mc-rfs-4 fw-bold">
                Période d'adhésion
              </Card.Title>
              <Row className="m-0">
                {["Saison", "Autre"].map((period) => (
                  <Col md={6} key={period} className="p-0">
                    <Form.Check
                      className="mc-rfs-5 mx-1"
                      type="radio"
                      label={period}
                      name="period"
                      value={period}
                      checked={values.period === period}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.period && !errors.period}
                      isInvalid={touched.period && !!errors.period}
                    />
                  </Col>
                ))}
                {!errors.period ? null : (
                  <Form.Control.Feedback
                    type="invalid"
                    className="mc-rfs-6 d-block fst-italic bg-danger lh-1 text-light p-1 ps-2 rounded"
                  >
                    {errors.period}
                  </Form.Control.Feedback>
                )}
              </Row>

              {/* Choix du mois d'adhésion ********************************************************/}
              {values.period === "Autre" ? (
                <Form.Group>
                  <Form.Select
                    name="periodMonth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.periodMonth}
                    isValid={touched.periodMonth && !errors.periodMonth}
                    isInvalid={touched.periodMonth && !!errors.periodMonth}
                    className="mc-rfs-5"
                  >
                    <option value={""}>Mois d'adhésion</option>
                    <option value={"Décembre"}>Décembre</option>
                    <option value={"Janvier"}>Janvier</option>
                    <option value={"Février"}>Février</option>
                    <option value={"Mars"}>Mars</option>
                    <option value={"Avril"}>Avril</option>
                    <option value={"Mai"}>Mai</option>
                    <option value={"Juin"}>Juin</option>
                  </Form.Select>
                  {!errors.periodMonth ? null : (
                    <Form.Control.Feedback
                      type="invalid"
                      className="mc-rfs-6 d-block fst-italic bg-danger text-light lh-1 p-1 rounded"
                    >
                      {errors.periodMonth}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <div className="d-none">{(values.periodMonth = "")}</div>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Choix du type d'offre ********************************************************************/}
        <Col sm={6} md={12} className="p-0">
          <Card className="m-1">
            <Card.Body className="p-2">
              <Card.Title className="mc-rfs-4 fw-bold">Type d'offre</Card.Title>
              <Row className="m-0">
                {["Baby", "Jeune", "Étudiant", "Adulte"].map((offre) => (
                  <Col md={3} key={offre} className="p-0">
                    <Form.Check
                      type="radio"
                      className="mc-rfs-5 mx-1"
                      label={offre}
                      name="offer"
                      value={offre}
                      checked={values.offer === offre}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.offer && !errors.offer}
                      isInvalid={touched.offer && !!errors.offer}
                    />
                  </Col>
                ))}
                {!errors.offer ? null : (
                  <Form.Control.Feedback
                    type="invalid"
                    className="mc-rfs-6 d-block fst-italic bg-danger lh-1 text-light p-1 ps-2 rounded"
                  >
                    {errors.offer}
                  </Form.Control.Feedback>
                )}
              </Row>

              {/* Choix du type d'offre enfant ou adulte ******************************************/}
              {values.offer ? (
                values.offer !== "Adulte" ? (
                  <Form.Group>
                    <Form.Select
                      name="offerNumPerson"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.offerNumPerson && !errors.offerNumPerson}
                      isInvalid={
                        touched.offerNumPerson && !!errors.offerNumPerson
                      }
                      className="mc-rfs-5"
                    >
                      <option value={""}>
                        Entrer le nb d'
                        {values.offer === "Baby" || values.offer === "Jeune"
                          ? "enfants"
                          : "étudiants"}
                      </option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                    </Form.Select>
                    {!errors.offerNumPerson ? null : (
                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6 d-block fst-italic bg-danger text-light lh-1 p-1 rounded"
                      >
                        {errors.offerNumPerson}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                ) : (
                  <Form.Group>
                    <Form.Select
                      name="offerNumPerson"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.offerNumPerson && !errors.offerNumPerson}
                      isInvalid={
                        touched.offerNumPerson && !!errors.offerNumPerson
                      }
                      className="mc-rfs-5"
                    >
                      <option value={""}>Entrer le nb d'adultes</option>
                      <option value={"seul" ? "1" : ""}>Seul</option>
                      <option value={"couple" ? "2" : ""}>Couple</option>
                    </Form.Select>
                    {!errors.offerNumPerson ? null : (
                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6 d-block fst-italic bg-danger text-light lh-1 p-1 rounded"
                      >
                        {errors.offerNumPerson}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )
              ) : (
                <div className="d-none">{(values.offerNumPerson = "")}</div>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Choix du nb de badges et de clés *********************************************************/}
        <Col sm={6} md={12} className="p-0">
          <Card className="m-1">
            <Card.Body className="p-2">
              <Card.Title className="mc-rfs-4 fw-bold">
                Caution pour badge et clé (5 € l'unité)
              </Card.Title>
              <Row>
                {/* Choix du nb de badges ***********************************************************/}
                <Col md={6}>
                  <Card className="border-0">
                    <Card.Header className="p-1 ps-0 border border-1 border-dark-subtle rounded mb-1">
                      <Form.Check
                        className="m-0 mx-2 mc-rfs-5"
                        type="checkbox"
                        label="Badge salle"
                        name="badge"
                        value={true}
                        checked={values.badge === true}
                        onChange={handleChange}
                      />
                    </Card.Header>
                    {values.badge ? (
                      <Form.Group>
                        <Form.Select
                          name="badgeNum"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.badgeNum && !errors.badgeNum}
                          isInvalid={touched.badgeNum && !!errors.badgeNum}
                          className="mc-rfs-5"
                        >
                          <option value={""}>Nb de badges</option>
                          <option value={"1"}>1</option>
                          <option value={"2"}>2</option>
                          <option value={"3"}>3</option>
                          <option value={"4"}>4</option>
                        </Form.Select>
                        {!errors.badgeNum ? null : (
                          <Form.Control.Feedback
                            type="invalid"
                            className="mc-rfs-6 d-block fst-italic bg-danger text-light lh-1 p-1 rounded"
                          >
                            {errors.badgeNum}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    ) : (
                      <div className="d-none">{(values.badgeNum = "")}</div>
                    )}
                  </Card>
                </Col>

                {/* Choix du nb de clés *************************************************************/}
                <Col md={6}>
                  <Card className="border-0">
                    <Card.Header className="p-1 ps-0 border border-1 border-dark-subtle rounded mb-1">
                      <Form.Check
                        className="m-0 mx-2 mc-rfs-5"
                        type="checkbox"
                        label="Clé extérieure"
                        name="key"
                        value={true}
                        checked={values.key === true}
                        onChange={handleChange}
                      />
                    </Card.Header>
                    {values.key ? (
                      <Form.Group>
                        <Form.Select
                          name="keyNum"
                          className="mc-rfs-5"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.keyNum && !errors.keyNum}
                          isInvalid={touched.keyNum && !!errors.keyNum}
                        >
                          <option value={""}>Nb de clés</option>
                          <option value={"1"}>1</option>
                          <option value={"2"}>2</option>
                        </Form.Select>
                        {!errors.keyNum ? null : (
                          <Form.Control.Feedback
                            type="invalid"
                            className="mc-rfs-6 d-block fst-italic bg-danger text-light lh-1 p-1 rounded"
                          >
                            {errors.keyNum}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    ) : (
                      <div className="d-none">{(values.keyNum = "")}</div>
                    )}
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DetailTarifs;
