import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SideMenu from "../../../components/menus/m-side";
import findImage from "../../../_utils/tools/findimage";
import tabInstallations from "../../../_params/tab-pages/leclub/tab-installations";
import { useNavHeight } from "../../../_utils/context/navheightprovider";

const NosInstallations = () => {
  const { navbarHeight } = useNavHeight();

  return (
    <div className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="m-auto">
            <h1 className="my-3 mc-rampart mc-rfs-2 mc-title text-center">
              Nos installations
            </h1>
            <div className="mc-container-club">
              <Row className="mc-container-histoire1">
                <Card className="border border-5 rounded-5 p-2 my-3 mc-card text-light">
                  <Card.Body className="p-0">
                    <Card.Title className="display-7 fw-bold text-center mb-3">
                      Notre clubhouse
                    </Card.Title>
                    <div className="d-flex justify-content-evenly">
                      <img
                        width={"31%"}
                        className="border border-2 rounded-4 border-dark-subtle"
                        src={findImage(tabInstallations, "clubVuDuCiel")}
                        alt=""
                      />
                      <img
                        width={"31%"}
                        className="border border-2 rounded-4 border-dark-subtle"
                        src={findImage(tabInstallations, "clubHouse1")}
                        alt=""
                      />
                      <img
                        width={"31%"}
                        className="border border-2 rounded-4 border-dark-subtle"
                        src={findImage(tabInstallations, "clubHouse2")}
                        alt=""
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Row>
              <Row className="mc-container-histoire2">
                <Card className="border border-5 rounded-5 p-2 my-3 mc-card text-light">
                  <Card.Body className="p-0">
                    <Card.Title className="display-7 fw-bold text-center mb-3">
                      Nos courts extérieurs
                    </Card.Title>
                    <div className="d-flex justify-content-evenly">
                      <img
                        width={"45%"}
                        src={findImage(tabInstallations, "courtsExterieurs")}
                        className="border border-2 rounded-4 border-dark-subtle"
                        alt=""
                      />
                      <img
                        width={"45%"}
                        src={findImage(tabInstallations, "courtsExterieurs1")}
                        className="border border-2 rounded-4 border-dark-subtle"
                        alt=""
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Row>
              <Row className="mc-container-histoire3">
                <Card className="border border-5 rounded-5 p-2 my-3 mc-card text-light">
                  <Card.Body className="p-0">
                    <Card.Title className="display-7 fw-bold text-center mb-3">
                      Nos courts couverts
                    </Card.Title>
                    <div className="d-flex justify-content-evenly">
                      <img
                        width={"58%"}
                        src={findImage(tabInstallations, "courtsCouvertsTCF")}
                        className="border border-2 rounded-4 border-dark-subtle"
                        alt=""
                      />
                      <img
                        width={"38%"}
                        src={findImage(tabInstallations, "courtsCouverts")}
                        alt=""
                        className="border border-2 rounded-4 border-dark-subtle"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NosInstallations;
