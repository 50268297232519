import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosBadminton from "../../../_params/tab-pages/animations/tab-badminton";
import MenuSaison from "../../../components/menus/m-saison";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";
import { useNavHeight } from "../../../_utils/context/navheightprovider";

const Badminton = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  //
  /* Tri de la table des photos de fin d'année en ordre décroissant */
  //
  const photosSorted = photosBadminton.sort((a, b) => b.season - a.season);
  //
  /* Récupération des saisons existant dans la table photos badminton */
  //
  const tabSaison = [];
  let season = "";

  photosSorted.map((saison) => {
    if (saison.season !== season) {
      season = saison.season;
      /* La propriété id est nécessaire pour compatibilité avec les autres tables utilisant MenuSaison */
      tabSaison.push({ id: season, season: saison.season });
    }
    return {
      tabSaison,
    };
  });
  /* FIN - Récupération des saisons existant dans la table photos badminton */

  return (
    <div className="mc-fond-badminton">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <MenuSaison saisons={tabSaison} />
          </Col>
          <Col md={9} className="gx-0">
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumbEvents />
            {/************************** Titre Badminton */}
            <Card bg="primary" className="m-2 border-0">
              <Card.Header className="mc-rampart mc-rfs-2 m-3 text-primary rounded-0 text-center bg-primary-subtle">
                Tournois de Badminton
              </Card.Header>
            </Card>

            {/************************** Partie Badminton */}
            {photosSorted.map((saison) => {
              return (
                <div key={saison.id}>
                  <Card bg="transparent" className="my-2 border-0">
                    <span
                      className="mc-marker"
                      style={{
                        top: -(navbarHeight + sideNavHeight + breadNavHeight),
                      }}
                      id={`saison${saison.season}`}
                    ></span>
                    <Card.Body
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                      className="m-2 p-2 rounded"
                    >
                      <Card.Header
                        className="mc-rampart mc-rfs-3 m-2 text-primary rounded"
                        style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                      >
                        {`SAISON ${saison.season}`}
                      </Card.Header>
                    </Card.Body>
                    <Card.Body
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                      className="m-2"
                    >
                      <Card.Text className="text-dark mc-rfs-4 mc-rfs-2 fw-bold">
                        {saison.num === 0
                          ? `Édition du ${saison.date} - ${saison.comment}`
                          : `${saison.num} doublettes lors de l'édition du ${saison.date}`}
                      </Card.Text>
                      <PhotoAlbum layout="rows" photos={saison.images} />
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Badminton;
