import React from "react";
import ImageZoom from "react-image-zooom";
import { motion } from "framer-motion";
import ImgMagnifierRect from "../../../_utils/tools/rect";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Table from "react-bootstrap/Table";
import tabEnseignement from "../../../_params/tab-pages/enseignement/tab-enseignement";
import findImage from "../../../_utils/tools/findimage";
import SideMenu from "../../../components/menus/m-side";
import { useNavHeight } from "../../../_utils/context/navheightprovider";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";

const imageVariants = {
  hover: {
    scale: 0.95,
    transition: { duration: 0.5 },
  },
};

const buttonVariants = {
  hover: {
    // scale: [1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1],
    scale: 1.3,
    textShadow: "0 0 8px rgb(255, 255, 255)",
    transition: { duration: 0.5, repeat: Infinity },
  },
};

const Enseignement = () => {
  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();

  return (
    <div className="mc-fond-courts-couverts">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <SideMenu />
          </Col>
          <Col md={9} className="gx-0">
            <div>
              {/******************************************************/}
              {/************************** Partie Equipe Pédagogique */}
              {/******************************************************/}
              <Card
                className="m-2 border border-4"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              >
                <span
                  className="mc-marker"
                  style={{
                    top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
                  }}
                  id="equipepedagogique"
                ></span>
                <Card.Header className="mc-rampart mc-rfs-2 m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
                  Équipe Pédagogique
                </Card.Header>
                <Row>
                  <Col md={3}>
                    <Card.Img
                      style={{ maxWidth: "130px", minWidth: "100px" }}
                      src={findImage(tabEnseignement, "Fred")}
                      alt="Photo Id?"
                      className="mx-3 rounded-3"
                    />
                  </Col>
                  <Col md={9}>
                    <Card.Body className="mx-2">
                      <Card.Text className="mc-rfs-3 fw-bold">
                        Frédéric GAUVRIT
                      </Card.Text>
                      <Card.Text className="text-primary fw-bold mc-rfs-4">
                        <span>Moniteur Diplômé d'état</span>
                        <br />
                        <span>Formation Tennis Santé / Bien-être</span>
                      </Card.Text>
                      <Card.Text className="fw-bold mc-rfs-4">
                        06 60 61 21 53
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>

              {/***************************************************/}
              {/************************** Partie Ecole de Tennis */}
              {/***************************************************/}
              <Card
                className="m-2 border border-4"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              >
                <span
                  className="mc-marker"
                  style={{
                    top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
                  }}
                  id="ecoletennis"
                ></span>
                <Card.Header className="mc-rampart mc-rfs-2 m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
                  École de tennis
                </Card.Header>
                <Card.Body className="mt-2 pb-0">
                  <Card.Title className="fw-bold text-primary mb-2">
                    Galaxie Tennis de 3 à 5 ans
                  </Card.Title>
                  <Card.Text>
                    Le programme Galaxie 3-5 ans, permet à l'enfant, grâce à un
                    enseignement adapté, de s'initier au tennis tout en
                    développant ses qualités psychomotrices.
                  </Card.Text>
                </Card.Body>
                <Card bg="transparent" className="mx-3 border-0">
                  <Card.Body className="mt-2 px-0 py-0">
                    <Card.Subtitle className="fw-bold my-2">
                      Les premiers pas dans Galaxie Tennis
                    </Card.Subtitle>
                  </Card.Body>
                  <Row>
                    <Col md={12} lg={7}>
                      <Card.Body className="px-0">
                        <Card.Text className="pe-2">
                          Ce programme répond aux besoins des enfants de 3 à 5
                          ans de bouger, de s’amuser et... d’apprendre ! Grâce à
                          un langage adapté, l'enseignant entre dans l'univers
                          de votre enfant et lui permet d'acquérir ses premières
                          empreintes techniques. L'utilisation d'outils
                          pédagogiques ludiques et variés colorent l’espace de
                          jeu et contribuent à l'aspect récréatif de l'activité.
                          <br />
                          <br />
                          Sous l’œil bienveillant de l’enseignant, l’enfant
                          expérimente de nouvelles situations et développe les
                          qualités nécessaires à sa future vie d’adulte :
                          persévérance, confiance, goût de l’effort, respect des
                          autres... tout en tapant ses premières balles !
                        </Card.Text>
                      </Card.Body>
                    </Col>
                    <Col md={12} lg={5}>
                      <Card.Img
                        src={findImage(tabEnseignement, "Galaxie")}
                        alt="Photo Galaxie?"
                        className="rounded-3"
                      />
                    </Col>
                  </Row>
                </Card>
                <Card bg="transparent" className="mx-3 border-0 rounded-0">
                  <Card.Body className="px-0">
                    <Card.Subtitle className="fw-bold my-2">
                      Déroulement d'une séance
                    </Card.Subtitle>
                    <Card.Text>
                      Un matériel adapté délimite des espaces de jeu où des
                      ateliers sont mis en place. Ainsi, les enfants évoluent
                      d'un atelier à l'autre par petits groupes en toute
                      sécurité. Ils pénètrent ainsi dans un univers de jeu, se
                      familiarisent avec la raquette, les ballons, les balles,
                      les cibles, expérimentent, apprennent et se sociabilisent.
                      En tant que parents, vous pouvez simplement accompagner
                      votre enfant ou bien participer à la séance au sein d’un
                      atelier. Galaxie 3 - 5 ans c’est aussi partager des
                      moments forts avec son enfant !
                    </Card.Text>
                    <Card.Text className="fw-bold">
                      Vous souhaitez inscrire votre enfant au tennis ?{" "}
                    </Card.Text>
                    <Card.Text>
                      n'hésitez pas à nous{" "}
                      <a className="text-muted fw-bold" href="/contact">
                        <span>contacter</span>
                      </a>{" "}
                      pour de plus amples informations.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card bg="transparent" className="mx-3 border-0 rounded-0">
                  <Card.Body className="px-0">
                    <Card.Title className="fw-bold text-primary mb-2">
                      Galaxie Tennis de 6 à 10 ans
                    </Card.Title>
                    <Card.Text>
                      Le programme Galaxie Tennis, place le jeu au coeur du
                      dispositif d'apprentissage et a pour objectif d’amener les
                      enfants à jouer dedans le respect des valeurs du sport.
                    </Card.Text>
                  </Card.Body>
                  <Row>
                    <Col xs={12} lg={4} className="gx-0">
                      <motion.div
                        className="m-2"
                        variants={imageVariants}
                        whileHover="hover"
                      >
                        <ImgMagnifierRect
                          width={"100%"}
                          src={findImage(tabEnseignement, "AfficheGalaxie")}
                        />
                      </motion.div>
                      <motion.div
                        className="m-2"
                        variants={imageVariants}
                        whileHover="hover"
                      >
                        <ImgMagnifierRect
                          width={"100%"}
                          src={findImage(tabEnseignement, "CriteresGalaxie")}
                        />
                      </motion.div>
                    </Col>
                    <Col xs={12} lg={8}>
                      <Card.Body className="px-0">
                        <Card.Subtitle className="fw-bold my-2">
                          Le jeu avant tout
                        </Card.Subtitle>
                        <Card.Text>
                          Pédagogie, matériel et terrains : tout est fait pour
                          permettre aux enfants de jouer dès les premières
                          séances. Ils s'amusent, se dépensent et progressent
                          tout en échangeant la balle ou en tentant de gagner
                          des points.
                          <br />
                          <br />
                          Ils apprennent ainsi à analyser la situation, à
                          s’adapter à différents styles de jeu et à forger leur
                          propre jeu.
                          <br />
                          <br />
                          L’enfant développe de concert ses qualités physiques
                          et psychologiques : vitesse, endurance, concentration,
                          agilité, coordination, adaptation, adresse, contrôle
                          de soi...
                        </Card.Text>
                      </Card.Body>
                      <Card.Body className="px-0">
                        <Card.Subtitle className="fw-bold my-2">
                          5 niveaux d'apprentissage
                        </Card.Subtitle>
                        <Card.Text>
                          Cinq niveaux permettent à l'enseignant de faire
                          évoluer progressivement les enfants du mini court au
                          court "des grands" en évaluant régulièrement leurs
                          compétences dans les journées « Jeu et Match » :
                        </Card.Text>
                        <ListGroup numbered className="mc-rfs-4">
                          <ListGroupItem className="border-0 bg-light">
                            <span className="fw-bold bg-dark text-light">
                              Terrain blanc
                            </span>{" "}
                            : 8 m de long sans filet avec ballon blanc (20 cm de
                            diamètre)
                          </ListGroupItem>
                          <ListGroupItem className="border-0 bg-light">
                            <span
                              className="fw-bold text-light"
                              style={{ backgroundColor: "purple" }}
                            >
                              Terrain violet
                            </span>{" "}
                            : 11 m avec filet à 0,50 m et une balle violette (12
                            à 15 cm de diamètre)
                          </ListGroupItem>
                          <ListGroupItem className="border-0 bg-light">
                            <span
                              className="fw-bold text-light"
                              style={{ backgroundColor: "red" }}
                            >
                              Terrain rouge
                            </span>{" "}
                            : 12,8 m avec filet de 0,80 m et balle rouge (7,5 cm
                            de diamètre)
                          </ListGroupItem>
                          <ListGroupItem className="border-0 bg-light">
                            <span
                              className="fw-bold text-light"
                              style={{ backgroundColor: "orange" }}
                            >
                              Terrain orange
                            </span>{" "}
                            : 18 m avec filet à 0,80 m et balle orange
                          </ListGroupItem>
                          <ListGroupItem className="border-0 bg-light">
                            <span
                              className="fw-bold text-light"
                              style={{ backgroundColor: "green" }}
                            >
                              Terrain vert
                            </span>{" "}
                            : Court de tennis traditionnel avec balle verte
                          </ListGroupItem>
                        </ListGroup>
                      </Card.Body>
                      <Card.Body className="px-0">
                        <Card.Subtitle className="fw-bold my-2">
                          Les valeurs par le sport
                        </Card.Subtitle>
                        <Card.Text>
                          Il n'y a pas de sport sans esprit sportif. Le respect
                          des règles du jeu, de l’adversaire, de l’enseignant,
                          de l’arbitre, le fair­play, l’honnêteté, la maîtrise
                          de soi dans la victoire comme dans la défaite : ces
                          valeurs du tennis dessinent un cadre autour de
                          l'activité des enfants pour qu'ils progressent tout en
                          prenant un maximum de plaisir sur le court. Les
                          enseignants et les bénévoles s’attachent également à
                          véhiculer cet état d’esprit au sein du club. Ainsi,
                          l’environnement est propice à l'intégration des
                          enfants dans la vie sportive du club. Les séances
                          d’enseignement hebdomadaires et les journées « Jeu &
                          Match » favorisent les échanges entre les enfants...
                          sur le court comme en-dehors des courts !
                        </Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                  <Card.Body className="px-0">
                    <Card.Subtitle className="fw-bold">
                      Objectif match !
                    </Card.Subtitle>
                    <Card.Text className="my-2">
                      L’objectif de Galaxie Tennis est d’amener tout
                      naturellement les enfants à faire des matchs en autonomie.
                      Des initiations aux matchs, des animations ou « plateaux »
                      (sans officialisation de résultat) et des compétitions
                      officielles (à partir de 8 ans et le niveau orange) sont
                      organisées dans le club tout au long de l’apprentissage
                      pour créer une atmosphère de compétition saine et motiver
                      les enfants...car le jeu c’est le match !
                    </Card.Text>
                  </Card.Body>

                  <Card.Body className="px-0">
                    <Card.Subtitle className="fw-bold my-2">
                      Vous souhaitez inscrire votre enfant au tennis ?{" "}
                    </Card.Subtitle>

                    <Card.Text>
                      n'hésitez pas à nous{" "}
                      <a className="text-muted fw-bold" href="/contact">
                        <span>contacter</span>
                      </a>{" "}
                      pour de plus amples informations.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card bg="transparent" className="mx-3 border-0 runded-0">
                  <Card.Body className="px-0">
                    <Card.Title className="fw-bold text-primary mb-2">
                      Le tennis ADOS de 11 à 16 ans
                    </Card.Title>
                    <Card.Text>
                      La pédagogie du programme Ados s'adapte au rythme et aux
                      attentes des adolescents afin de favoriser leur
                      progression et leur investissement.
                      <br />
                      Un rythme adapté
                      <br />
                      Les adolescents sont formés selon{" "}
                      <span className="fw-bold text-danger">
                        un programme annuel
                      </span>{" "}
                      basé sur des séances hebdomadaires de 2h30 encadrées par
                      notre enseignant.
                      <br />
                      En période de vacances scolaires, des stages peuvent leur
                      permettre d’accélérer leurs progrès grâce à une pratique
                      plus intensive.
                    </Card.Text>
                  </Card.Body>
                  <Row>
                    <Col md={12} lg={6}>
                      <Card.Body className="px-0">
                        <Card.Subtitle className="fw-bold">
                          Gagner en autonomie
                        </Card.Subtitle>
                        <Card.Text>
                          En parallèle de ces séances, l’encadrement du club
                          encourage le jeu libre en aidant les adolescents à
                          s’organiser, planifier des matchs entre eux et
                          réserver leurs terrains eux-mêmes. Pour faciliter
                          cette prise d’autonomie, certains créneaux horaires
                          leur sont par exemple réservés en journée pendant les
                          vacances scolaires. Des séances « Jeu & Matchs » sont
                          également organisées pour évaluer les progrès de
                          chacun et fixer tous ses prochains objectifs.
                        </Card.Text>
                      </Card.Body>
                      <Card.Body className="px-0">
                        <Card.Subtitle className="fw-bold">
                          Dans la vie du club
                        </Card.Subtitle>
                        <Card.Text>
                          Un des objectifs essentiel du programme ADOS est de
                          valoriser la place des adolescents au sein du club :
                          en leur confiant des missions lors d’événements
                          réunissant l’ensemble des adhérents du club (tournois,
                          rencontres par équipes, animations diverses), en les
                          orientant vers des formations d’arbitrage ou
                          d’initiateur, en les aidant à créer une commission
                          ADOS au sein du club. Le club favorisera leur
                          intégration, et surtout, leur épanouissement sportif
                          comme humain.
                        </Card.Text>
                      </Card.Body>
                    </Col>
                    <Col md={12} lg={6} className="gx-0">
                      <Card.Img
                        src={findImage(tabEnseignement, "Ados")}
                        alt="Photos Ados?"
                        className="m-2 border border-3 rounded-3"
                      />
                    </Col>
                  </Row>
                  <Card.Body className="px-0">
                    <Card.Subtitle className="fw-bold">
                      Vous souhaitez inscrire votre enfant au tennis ?{" "}
                    </Card.Subtitle>
                    <Card.Text>
                      n'hésitez pas à nous{" "}
                      <a className="text-muted fw-bold" href="/contact">
                        <span>contacter</span>
                      </a>{" "}
                      pour de plus amples informations.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Card>

              {/***************************************************/}
              {/************************** Partie Tennis Adultes  */}
              {/***************************************************/}
              <Card
                className="m-2 border border-4"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              >
                <span
                  className="mc-marker"
                  style={{
                    top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
                  }}
                  id="tennisadulte"
                ></span>
                <Card.Header className="mc-rampart mc-rfs-2 m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
                  Tennis Adultes
                </Card.Header>
                <Row>
                  <Col md={12} lg={7} className="gx-0">
                    <Card bg="transparent" className="m-3 border-0">
                      <Card.Body className="mt-2 px-0 py-0">
                        <Card.Text>
                          1h à une 1h15 par semaine en soirée du lundi au
                          vendredi et de septembre à juin (hors vacances
                          scolaires).
                          <br />
                          Les joueurs et joueuses sont regroupés en fonction de
                          leur niveau ou de leur classement.
                        </Card.Text>
                        <Card.Title className="fw-bold text-primary mb-2">
                          Tennis découverte
                        </Card.Title>
                        <Card.Subtitle className="fw-bold my-2 fst-italic">
                          Destiné aux non initiés ou pratiquants occasionnels
                          quel que soit leur âge
                        </Card.Subtitle>
                        <Card.Text>
                          L'initiation, encadrée par notre moniteur diplômé,
                          s'oriente vers le positionnement des appuis, la
                          maîtrise des trajectoires en coup droit et en revers,
                          l'apprentissage de notions tactiques et la mise en
                          situation par des jeux permettant de prendre du
                          plaisir dès les premières heures de cours.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card bg="transparent" className="m-3 border-0">
                      <Card.Body className="mt-2 px-0 py-0">
                        <Card.Title className="fw-bold text-primary mb-2">
                          Tennis perfectionnement
                        </Card.Title>
                        <Card.Subtitle className="fw-bold my-2 fst-italic">
                          Destiné aux adultes initiés quel que soit leur niveau
                        </Card.Subtitle>
                        <Card.Text>
                          Le tennis perfectionnement, basé sur une pédagogie
                          mise en oeuvre par le club et son moniteur diplômé,
                          s'appuie sur un enseignement adapté à chaque étape
                          d'apprentissage. Elle permet aux joueurs et joueuses,
                          désireux de s'orienter vers davantage de compétition
                          ou simplement de continuer à progresser dans un
                          environnement convivial, d'améliorer leur maîtrise des
                          techniques de bases du tennis (coup droit, revers,
                          volée, smash, déplacement, reprise d'appuis, ...).
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card bg="transparent" className="m-3 border-0">
                      <Card.Body className="mt-2 px-0 py-0">
                        <Card.Title className="fw-bold text-primary mb-2">
                          Tennis compétition
                        </Card.Title>
                        <Card.Text>
                          Il est réservé aux joueurs et joueuses compétiteurs
                          réguliers. Les fondamenteaux technico-tactiques sont
                          acquis chez ces joueurs qui cherchent à solidifier
                          tous les secteurs de leur jeu. Ils vont renforcer
                          leurs points faibles et faire ressortir leurs points
                          forts afin d'optimiser leur tennis.
                        </Card.Text>
                        <Card.Text>
                          À raison d'une séance d'1h15mn par semaine un
                          entraînement intensif adapté au niveau de chaque
                          groupe est proposé.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12} lg={4} className="gx-0">
                    <Image
                      src={findImage(tabEnseignement, "Adulte1")}
                      alt="Photos Adulte?"
                      className="m-2 border border-3 rounded-3"
                      thumbnail
                    />
                    <Image
                      src={findImage(tabEnseignement, "Adulte2")}
                      alt="Photos Adulte?"
                      className="m-2 border border-3 rounded-3"
                      thumbnail
                    />
                  </Col>
                </Row>
              </Card>

              {/****************************************************/}
              {/************************** Partie Stages de Tennis */}
              {/****************************************************/}
              <Card
                className="m-2 border border-4"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              >
                <span
                  className="mc-marker"
                  style={{
                    top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
                  }}
                  id="stagetennis"
                ></span>
                <Card.Header className="m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
                  <Card.Text className="mc-rampart mc-rfs-2 m-0">
                    Stages de Tennis
                  </Card.Text>
                  <Nav className="mc-rfs-4 position-absolute top-0 end-0 mt-3 me-4">
                    <NavLink
                      className="p-1 px-2 border border-2 border-muted bg-primary rounded text-center nav-link"
                      to="/enseignement/stages"
                    >
                      <motion.div
                        className="text-light fw-semibold"
                        variants={buttonVariants}
                        whileHover="hover"
                      >
                        <span className="pe-2 align-middle">Voir</span>
                        <IconContext.Provider value={{ size: "1em" }}>
                          <FaChevronRight />
                        </IconContext.Provider>
                      </motion.div>
                    </NavLink>
                  </Nav>
                </Card.Header>
                <Card
                  bg="transparent"
                  className="flex-lg-row justify-content-between border-0"
                >
                  <Card.Body>
                    <Card.Img
                      src={findImage(tabEnseignement, "StageP1")}
                      alt="Photo Stage?"
                      className="rounded-3"
                    />
                  </Card.Body>
                  <Card.Body>
                    <Card.Img
                      src={findImage(tabEnseignement, "StageP2")}
                      alt="Photo Stage?"
                      className="rounded-3"
                    />
                  </Card.Body>
                </Card>
              </Card>

              {/************************************************************/}
              {/************************** Partie Tennis Santé / Bien-Être */}
              {/************************************************************/}
              <Card
                className="m-2 border border-4"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
              >
                <span
                  className="mc-marker"
                  style={{
                    top: -(navbarHeight + sideNavHeight + breadNavHeight + 10),
                  }}
                  id="tennissante"
                ></span>
                <Card bg="transparent" className="border-0">
                  {/* <Card.Header className="mc-rampart mc-rfs-2 m-2 py-2 px-1 fw-bold text-primary text-center rounded-2 bg-light">
                    Tennis Santé - Bien-Être
                  </Card.Header> */}
                  <Card.Header className="m-2 py-2 px-0 fw-bold text-primary text-center rounded-2 bg-light">
                    <Card.Text className="mc-rampart mc-rfs-2 m-0">
                      Tennis Santé - Bien-Être
                    </Card.Text>
                    <Nav className="mc-rfs-4 position-absolute top-0 end-0 mt-3 me-4">
                      <NavLink
                        className="p-1 px-2 border border-2 border-muted bg-success rounded text-center nav-link"
                        to="/enseignement/tennissante"
                      >
                        <motion.div
                          className="text-light fw-semibold"
                          variants={buttonVariants}
                          whileHover="hover"
                        >
                          <span className="pe-2 align-middle">Voir</span>
                          <IconContext.Provider value={{ size: "1em" }}>
                            <FaChevronRight />
                          </IconContext.Provider>
                        </motion.div>
                      </NavLink>
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title
                      className="fw-bold"
                      style={{ color: "rgb(250, 100, 0)" }}
                    >
                      Le tennis au service de la santé, un véritable enjeu de
                      santé publique
                    </Card.Title>
                  </Card.Body>
                  <Row className="g-0">
                    <Col lg={7}>
                      <Card.Body className="py-0">
                        <Card.Text>
                          Le tennis est un sport qui contribue au bien-être
                          physique, mental et social, les trois composantes de
                          la définition de la santé. Il se révèle, en effet,
                          être en tête dans les études internationales en termes
                          de bénéfices pour la santé.
                          <br />
                          Facteur de prévention, il est aussi un véritable outil
                          thérapeutique dans la lutte contre les maladies
                          chroniques et le vieillissement. Il peut se jouer à
                          tous les âges et quel que soit son passé sportif.
                          <br />
                          La FFT a ainsi créé un programme adapté et sécurisé
                          destiné à tous les publics, notamment aux personnes
                          atteintes de certaines pathologies chroniques et/ou
                          concernées par le vieillissement :{" "}
                          <span
                            className="fw-bold"
                            style={{ color: "rgb(250, 100, 0)" }}
                          >
                            le Tennis Santé
                          </span>
                          .
                        </Card.Text>
                      </Card.Body>
                    </Col>
                    <Col lg={5}>
                      <Card
                        style={{ backgroundColor: "rgb(250, 100, 0)" }}
                        className="mx-2 my-4 p-2 rounded-3 text-center border-0"
                      >
                        <Table
                          responsive
                          hover
                          bordered
                          className="m-0"
                          style={{
                            border: "rgba(225, 100, 0, 1)",
                            backgroundColor: "rgba(225, 210, 156, 1)",
                          }}
                        >
                          <thead
                            className="text-light"
                            style={{
                              backgroundColor: "rgba(250, 100, 0, 1)",
                            }}
                          >
                            <tr>
                              <th colSpan={"2"}>
                                Horaires Tennis Santé - Bien-être
                              </th>
                            </tr>
                          </thead>
                          <tbody className="fw-bold">
                            <tr>
                              <td>Lundi</td>
                              <td>Jeudi</td>
                            </tr>
                            <tr>
                              <td>16h à 17h15</td>
                              <td>9h30 à 10h45</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </Col>
                  </Row>
                  <Card.Body className="pb-0">
                    <Card.Subtitle className="fw-bold mb-2">
                      La FFT, une fédération innovante dans le développement du
                      sport santé
                    </Card.Subtitle>
                  </Card.Body>
                  <Row className="g-0">
                    <Col lg={7}>
                      <Card.Body className="py-0">
                        <Card.Text>
                          Dès 2014, la Fédération Française de Tennis a réuni
                          une partie de ses entités (le pôle fédéral, la
                          direction technique nationale, la commission fédérale
                          médicale et celles des ligues) pour mettre en place le
                          programme Tennis Santé, en accord avec les
                          recommandations du CNOSF. Son objectif est alors de
                          prévenir et de réduire les facteurs de risque ainsi
                          que les limitations fonctionnelles liées à la maladie.
                          La FFT a ainsi collaboré avec des médecins
                          spécialistes – le docteur Caroline Cuvier (oncologue),
                          le professeur Étienne Larger (endocrinologue) et le
                          professeur Nicolas Mansencal (cardiologue) – afin
                          d’élaborer des protocoles adaptés aux différents
                          publics cibles. Depuis 2016, l’activité physique et
                          sportive (APS) est reconnue dans le Code de santé
                          publique comme une thérapeutique non médicamenteuse
                          pouvant être prescrite par les médecins.
                        </Card.Text>
                      </Card.Body>
                    </Col>
                    <Col lg={5}>
                      <Card.Img
                        src={findImage(tabEnseignement, "Label")}
                        alt="Photo Label?"
                        className="mx-2 my-4 rounded-3"
                        style={{ width: "90%" }}
                      />
                    </Col>
                  </Row>
                  <Card.Body>
                    <Card.Subtitle className="fw-bold mb-2">
                      Le Tennis Santé : outil thérapeutique, plaisir et lien
                      social
                    </Card.Subtitle>
                    <Card.Text>
                      Le programme Tennis Santé est adapté aux personnes
                      concernées par le vieillissement et/ou atteintes de
                      diverses pathologies chroniques :
                    </Card.Text>
                    <ListGroup numbered className="ms-2">
                      <ListGroupItem className="border-0 bg-transparent py-1">
                        artériopathie oblitérante des membres inférieurs (AOMI)
                        ;
                      </ListGroupItem>
                      <ListGroupItem className="border-0 bg-transparent py-1">
                        hypertension artérielle (HTA) ;
                      </ListGroupItem>
                      <ListGroupItem className="border-0 bg-transparent py-1">
                        coronaropathies ;
                      </ListGroupItem>
                      <ListGroupItem className="border-0 bg-transparent py-1">
                        surpoids et obésité ;
                      </ListGroupItem>
                      <ListGroupItem className="border-0 bg-transparent py-1">
                        diabète de type 2 ;
                      </ListGroupItem>
                      <ListGroupItem className="border-0 bg-transparent py-1">
                        stress, anxiété, dépression ;
                      </ListGroupItem>
                      <ListGroupItem className="border-0 bg-transparent py-1">
                        cancer (sein, prostate, colon) ;
                      </ListGroupItem>
                      <ListGroupItem className="border-0 bg-transparent py-1">
                        pathologies articulaires (arthrose, spondylarthrite,
                        polyarthrite) ;
                      </ListGroupItem>
                      <ListGroupItem className="border-0 bg-transparent pt-1 pb-4">
                        bronchopneumopathie chronique obstructive (BPCO).
                      </ListGroupItem>
                    </ListGroup>
                    <Card.Text>
                      En plus de ses vertus et/ou effets thérapeutiques, le
                      Tennis Santé procure beaucoup de plaisir et contribue au
                      maintien et au développement du lien social.
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    <Card.Title
                      className="fw-bold"
                      style={{ color: "rgb(250, 100, 0)" }}
                    >
                      Le Tennis Santé: une pratique adaptée, personnalisée et
                      sécurisée
                    </Card.Title>
                  </Card.Body>
                  <Card.Body className="py-0">
                    <Card.Text>
                      La FFT contribue à la santé de ses pratiquants grâce au
                      développement du Tennis Santé.
                      <br />
                      Le Tennis Santé doit savoir s’adapter à l’âge et à l’état
                      de santé de chacun.
                      <br />
                      Pour cela et afin de garantir une pratique sécurisée, une
                      formation fédérale a été mise en place dans chaque ligue.
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    <Card.Subtitle className="fw-bold pb-2">
                      Déploiement du programme national
                    </Card.Subtitle>
                    <Card.Text>
                      Le développement du programme Tennis Santé au niveau
                      national repose sur un trinôme composé d’un médecin
                      référent, d’un enseignant diplômé d’État et d’un
                      conseiller en développement. Ils ont pour mission
                      l’accompagnement des clubs volontaires et la formation des
                      enseignants basée sur le programme fédéral Tennis Santé
                      donnant toutes les clés et informations pour initier la
                      pratique du Tennis Santé.
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    <Card.Subtitle className="fw-bold pb-2">
                      L’enseignant : la clé du succès de la pratique
                    </Card.Subtitle>
                    <Card.Text>
                      Les formations Tennis Santé sont pour l’instant réservées
                      aux enseignants professionnels de Tennis (DE ou DES).
                      <br />À l’issue de cette formation, l’enseignant connaît
                      les caractéristiques très générales des principales
                      pathologies chroniques et des effets du vieillissement,
                      est capable d’encourager les comportements favorables à la
                      santé, d’évaluer les capacités physiques de chacun, de
                      concevoir une séance adaptée la plus ludique possible,
                      d’encourager et de motiver.
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    <Card.Subtitle className="fw-bold pb-2">
                      Les séances de Tennis Santé: des moments de plaisir et de
                      partage
                    </Card.Subtitle>
                  </Card.Body>
                  <Row className="g-0">
                    <Col lg={7}>
                      <Card.Body className="py-0">
                        <Card.Text>
                          Sous la forme d’une séance collective hebdomadaire
                          rassemblant huit joueurs maximum, les enseignants
                          s’appuient sur un matériel évolutif qui permet à
                          chaque pratiquant, novice ou non, de réaliser des
                          échanges et de s’amuser dès la première séance. Ce
                          matériel s’adapte alors à l’état de santé, à l’âge et
                          aux capacités physiques de chacun :
                        </Card.Text>
                      </Card.Body>
                      <ListGroup numbered className="ms-3">
                        <ListGroupItem className="border-0 bg-transparent py-1">
                          terrains de taille variable ;
                        </ListGroupItem>
                        <ListGroupItem className="border-0 bg-transparent py-1">
                          filets de hauteur modulable ;
                        </ListGroupItem>
                        <ListGroupItem className="border-0 bg-transparent py-1">
                          balles plus lentes ;
                        </ListGroupItem>
                        <ListGroupItem className="border-0 bg-transparent py-1">
                          raquettes de taille adaptée permettant ainsi de
                          limiter les déplacements et de faciliter les échanges.
                        </ListGroupItem>
                        <ListGroupItem className="border-0 bg-transparent pt-1 pb-4">
                          bronchopneumopathie chronique obstructive (BPCO).
                        </ListGroupItem>
                      </ListGroup>{" "}
                    </Col>
                    <Col lg={5}>
                      <Card.Img
                        src={findImage(tabEnseignement, "TennisSante")}
                        alt="Tennis santé?"
                        className="ms-3 rounded-3"
                        style={{ width: "95%" }}
                      />
                    </Col>
                  </Row>
                  <Card.Body>
                    <Card.Text>
                      Ces séances débutent toujours avec un temps suffisant
                      réservé à l’accueil. L’enseignant doit prendre le temps de
                      s’informer sur la forme et les éventuels problèmes de
                      chacun. Après l’énoncé des objectifs de la séance,
                      l’échauffement qui est un temps très important peut
                      commencer.
                      <br />
                      L’enseignant doit aussi chercher à développer tous les
                      éléments de la condition physique : endurance, force
                      musculaire, équilibre, souplesse. Tout au long de la
                      séance, l’enseignant devra respecter les trois principes
                      fondamentaux d’une séance sport-santé :
                    </Card.Text>
                  </Card.Body>
                  <ListGroup numbered className="ms-3 border-0">
                    <ListGroupItem className="border-0 bg-transparent py-1">
                      adaptation à chacun ;
                    </ListGroupItem>
                    <ListGroupItem className="border-0 bg-transparent py-1">
                      progressivité ;
                    </ListGroupItem>
                    <ListGroupItem className="border-0 bg-transparent pt-1 pb-4">
                      et enfin plaisir et réussite.
                    </ListGroupItem>
                  </ListGroup>{" "}
                  <Card.Body className="py-0">
                    <Card.Text>
                      Retour au calme et debriefing clôturent la séance dans un
                      dernier moment de convivialité.
                      <br />
                      Ecoute, vigilance et bienveillance doivent faire partie de
                      l’ADN de l’enseignant.
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    <Card.Subtitle className="fw-bold mb-2">
                      Vous êtes intéressé ou souhaitez vous inscrire ?{" "}
                    </Card.Subtitle>
                    <Card.Text>
                      N'hésitez pas à nous{" "}
                      <a className="text-muted fw-bold" href="/contact">
                        <span>contacter</span>
                      </a>
                      .
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="flex-lg-row justify-content-between mx-4 mt-3 border-0">
                  <Card.Body className="m-2 rounded-3 border border-5 border-warning">
                    <motion.div variants={imageVariants} whileHover="hover">
                      <ImageZoom
                        src={findImage(tabEnseignement, "TennisSanteAffiche")}
                        alt="Affiche Tennis Santé?"
                        zoom="200"
                      />
                    </motion.div>
                  </Card.Body>
                  <Card.Body className="m-2 rounded-3 border border-5 border-warning">
                    <motion.div variants={imageVariants} whileHover="hover">
                      <ImageZoom
                        src={findImage(tabEnseignement, "TennisSanteArticle")}
                        alt="Article Tennis Santé?"
                        zoom="200"
                      />
                    </motion.div>
                  </Card.Body>
                </Card>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Enseignement;
