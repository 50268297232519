import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { motion } from "framer-motion";
import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";
import "../../../assets/css/reservations.css";

const buttonVariants = {
  hover: {
    // scale: [1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1],
    scale: 1.2,
    textShadow: "0 0 8px rgb(255, 255, 255)",
    transition: { duration: 0.5, repeat: Infinity },
  },
};

const Reservations = () => {
  return (
    <div className="mc-fond-courts-couverts">
      <Container fluid>
        <Row className="justify-content-center">
          <Col md={5} className="my-4">
            <Card className="mt-2 border border-4 border-black rounded-4 shadow-lg p-2 bg-light">
              <Card.Header className="mc-rfs-4 fw-bold bg-danger rounded-2 text-center text-light">
                Réservation des courts couverts
              </Card.Header>
              <Card.Body
                className="p-0 pt-3 mc-rfs-5"
                style={{ height: "17vh" }}
              >
                <Card.Text className="lh-1">
                  La réservation se fait via l'application <b>CAFIRESA</b>.
                  <br />
                  Connectez-vous avec votre identifiant et mot de passe.
                </Card.Text>
                <Card.Text>
                  Pour toute question, n'hésitez pas à consulter
                  <Button
                    role="button"
                    variant="outline-secondary"
                    href="/cafiresaAide.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-2 px-2 p-0 border border-3 shadow-sm"
                    // size="sm"
                  >
                    <motion.span
                      className="g-0"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-1 fw-semibold mc-rfs-5">Aide</span>
                      <IconContext.Provider value={""}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.span>
                  </Button>
                </Card.Text>
              </Card.Body>
              <Card className="mt-2">
                <Card.Img
                  src={"/cafiresa-500x349.jpg"}
                  className="img-fluid"
                  alt=""
                />
                <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center">
                  <motion.div variants={buttonVariants} whileHover="hover">
                    <Button
                      role="button"
                      variant="warning"
                      href="http://www.cafiresa.com/atcpb"
                      target="_blank"
                      rel="noopener noreferrer"
                      // className="mt-2 border border-4 border-danger text-nowrap"
                      className="mc-btn-resa p-0"
                    >
                      <span className="pe-2 fw-semibold mc-rfs-5">
                        Réserver
                      </span>
                      <IconContext.Provider className="mc-rfs-5" value={""}>
                        <FaChevronRight />
                      </IconContext.Provider>
                      <div className="mc-flashing-point1"></div>
                    </Button>
                  </motion.div>
                </Card.ImgOverlay>
              </Card>
            </Card>
          </Col>
          <Col md={5} className="my-4">
            <Card className="mt-2 border border-4 border-black rounded-4 shadow-lg p-2 bg-light">
              <Card.Header className="fw-bold mc-rfs-4 bg-success rounded-2 text-center text-light">
                Réservation des courts extérieurs
              </Card.Header>
              <Card.Body
                className="p-0 pt-3 mc-rfs-5"
                style={{ height: "17vh" }}
              >
                <Card.Text className="lh-1">
                  La réservation se fait via l'application <b>TEN'UP</b>
                  <br />
                  Connectez-vous avec votre identifiant et mot de passe.
                </Card.Text>
                <Card.Text>
                  Accéder au menu <b>"RÉSERVER / Dans mon club"</b>
                </Card.Text>
              </Card.Body>
              <Card className="mt-2">
                <Card.Img
                  src={"/resatenup-500x349.jpg"}
                  className="img-fluid"
                  alt=""
                />
                <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center">
                  <motion.div variants={buttonVariants} whileHover="hover">
                    <Button
                      role="button"
                      // variant="warning"
                      href="https://auth.fft.fr/auth/realms/master/protocol/openid-connect/auth?client_id=FED_MET&response_type=code&scope=openid&redirect_uri=https://tenup.fft.fr/user-auth/process&atuserid=8d3c1a1e-ab7b-42d4-bc7f-8185bf5b7fa7"
                      target="_blank"
                      rel="noopener noreferrer"
                      // className="border border-4 border-danger text-nowrap"
                      className="mc-btn-resa text-nowrap"
                      size="sm"
                    >
                      <span className="pe-2 fw-semibold mc-rfs-5">
                        Réserver
                      </span>
                      <IconContext.Provider className="mc-rfs-5" value={""}>
                        <FaChevronRight />
                      </IconContext.Provider>
                      <div className="mc-flashing-point2"></div>
                    </Button>
                  </motion.div>
                </Card.ImgOverlay>
              </Card>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Reservations;
