import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "../../components/error/c-errorpage";
import {
  PublicLayout,
  Accueil,
  Contact,
  Enseignement,
  LeClub,
  NotreHistoire,
  LeBureau,
  NosInstallations,
  OuSommesNous,
  MentionsLegales,
  Animations,
  Programme,
  Ventes,
  FeteEcole,
  SoireeClub,
  Pickleball,
  Badminton,
  Competitions,
  Jchampt,
  Jtournoi,
  Schampt,
  Stournoi,
  Stinterne,
  Reservations,
  Events,
  Events30Ans,
  Events40Ans,
  Jumelage,
  Partenaires,
  Stages,
  TennisSante,
} from "../../router/public";

const PublicRoute = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route index element={<Accueil />} />
        <Route path="/accueil" element={<Accueil />} />
        <Route path="/leclub" element={<LeClub />} />
        {/******************************* Pages Le Club ***************************/}
        <Route>
          <Route path="/leclub/notrehistoire" element={<NotreHistoire />} />
          <Route
            path="/leclub/nosinstallations"
            element={<NosInstallations />}
          />
          <Route path="/leclub/ousommesnous" element={<OuSommesNous />} />
          <Route path="/leclub/lebureau" element={<LeBureau />} />
          <Route path="/leclub/mentionslegales" element={<MentionsLegales />} />
        </Route>
        <Route path="/competitions" element={<Competitions />} />
        {/******************************* Pages Competitions ***************************/}
        <Route>
          <Route path="/competitions/jchampt" element={<Jchampt />} />
          <Route path="/competitions/schampt" element={<Schampt />} />
          <Route path="/competitions/jtournoi" element={<Jtournoi />} />
          <Route path="/competitions/stournoi" element={<Stournoi />} />
          <Route path="/competitions/stinterne" element={<Stinterne />} />
        </Route>
        {/******************************* Page Enseignement ***************************/}
        <Route path="/enseignement" element={<Enseignement />} />
        <Route>
          <Route path="/enseignement/stages" element={<Stages />} />
          <Route path="/enseignement/tennissante" element={<TennisSante />} />
        </Route>
        {/******************************* Page Contacts *******************************/}
        <Route path="/contact" element={<Contact />} />
        <Route path="/animations" element={<Animations />} />
        {/******************************* Pages Animations ****************************/}
        <Route>
          <Route path="/animations/programme" element={<Programme />} />
          <Route path="/animations/ventes" element={<Ventes />} />
          <Route path="/animations/feteecole" element={<FeteEcole />} />
          <Route path="/animations/soireeclub" element={<SoireeClub />} />
          <Route path="/animations/pickleball" element={<Pickleball />} />
          <Route path="/animations/badminton" element={<Badminton />} />
        </Route>
        {/******************************* Page Réservations ***************************/}
        <Route path="/reservations" element={<Reservations />} />
        {/******************************* Pages Évènements ****************************/}
        <Route path="/events" element={<Events />} />
        <Route path="/events/30ans" element={<Events30Ans />} />
        <Route path="/events/40ans" element={<Events40Ans />} />
        <Route path="/events/jumelage" element={<Jumelage />} />
        {/******************************* Page Devenez Partenaires ********************/}
        <Route path="/partenaires" element={<Partenaires />} />
        {/******************************* Page Not Found ******************************/}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};

export default PublicRoute;
