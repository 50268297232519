import les3Presidents from "../../../assets/img/img-notrehistoire/les3Presidents.jpg";
import declaration from "../../../assets/img/img-notrehistoire/declarationJOTCF.png";
import clubHouse from "../../../assets/img/img-notrehistoire/clubHouse.jpg";
import courtsExterieurs from "../../../assets/img/img-notrehistoire/courtsExterieurs.jpg";
import inaugurationCourtsExterieurs from "../../../assets/img/img-notrehistoire/inaugurationCourtsExterieurs.jpg";
import inondationCourts from "../../../assets/img/img-notrehistoire/inondationCourts.jpg";
import inaugurationCh from "../../../assets/img/img-notrehistoire/inaugurationCh.jpg";
import inaugurationChBureau from "../../../assets/img/img-notrehistoire/inaugurationChBureau.jpg";
import tournoiTCF from "../../../assets/img/img-notrehistoire/tournoiTCF1.jpg";
import evolutionTCF from "../../../assets/img/img-notrehistoire/evolutionTCF.png";

const tabNotreHistoire = [
  { id: "les3Presidents", image: les3Presidents },
  { id: "declaration", image: declaration },
  { id: "clubHouse", image: clubHouse },
  { id: "courtsExterieurs", image: courtsExterieurs },
  { id: "inaugurationCourtsExterieurs", image: inaugurationCourtsExterieurs },
  { id: "inondationCourts", image: inondationCourts },
  { id: "inaugurationCh", image: inaugurationCh },
  { id: "inaugurationChBureau", image: inaugurationChBureau },
  { id: "tournoiTCF", image: tournoiTCF },
  { id: "evolutionTCF", image: evolutionTCF },
];

export default tabNotreHistoire;
