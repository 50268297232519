// Function to retrieve any image from specified array.

const findImage = (array, img) => {
  const item = array.find((item) => item.id === img);
  if (item) {
    return item.image;
  } else {
    console.log("item: ", item);
  }
};

export default findImage;
