import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { IconContext } from "react-icons";
import { FaChevronRight } from "react-icons/fa";
import { motion } from "framer-motion";
import imgAnim from "../../../assets/img/images-anim";
import { NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";

const buttonVariants = {
  hover: {
    // scale: [1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1, 1.2, 1],
    scale: 1.3,
    textShadow: "0 0 8px rgb(255, 255, 255)",
    transition: { duration: 0.5, repeat: Infinity },
  },
};

const Animations = () => {
  return (
    <div className="mc-fond-ecran">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col sm={6} md={4}>
            <Card className="p-2 m-2 text-light">
              <Card.Img src={imgAnim[4]} width={100} alt={""} />
              <Card.ImgOverlay>
                <Card.Title>Programme 2024</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  Découvrez toutes les animations prévues cette saison ...
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    className="p-1 px-2 border border-2 border-muted bg-primary rounded text-center nav-link"
                    to="/animations/programme"
                  >
                    <motion.div
                      className="text-light fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2 align-middle">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={6} md={4}>
            <Card className="p-2 m-2 text-light">
              <Card.Img src={imgAnim[1]} width={100} alt={""} />

              <Card.ImgOverlay>
                <Card.Title>Vente de Fromages et de Vins</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  Soutenez le club en passant une commande ...
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    className="p-1 px-2 border border-2 border-muted bg-success rounded text-center nav-link"
                    to="/animations/ventes"
                  >
                    <motion.div
                      className="text-light fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2 align-middle">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={6} md={4}>
            <Card className="p-2 m-2">
              <Card.Img src={imgAnim[6]} width={100} alt={""} />
              <Card.ImgOverlay>
                <Card.Title>Fête de l'école de tennis</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  En juin participez à la fête des enfants inscrits à l'école de
                  tennis ...
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    className="p-1 px-2 border border-2 border-muted bg-info rounded text-center nav-link"
                    to="/animations/feteecole"
                  >
                    <motion.div
                      className="text-light fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2 align-middle">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={6} md={4}>
            <Card className="p-2 m-2">
              <Card.Img src={imgAnim[7]} width={100} alt={""} />
              <Card.ImgOverlay>
                <Card.Title>Soirée Club</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  Soirée d'été entre amis ...
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    className="p-1 px-2 border border-2 border-muted bg-secondary rounded text-center nav-link"
                    to="/animations/soireeclub"
                  >
                    <motion.div
                      className="text-light fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2 align-middle">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={6} md={4}>
            <Card className="p-2 m-2 text-light">
              <Card.Img src={imgAnim[0]} width={100} alt={""} />
              <Card.ImgOverlay>
                <Card.Title>Badminton</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  Tournois de la Saint Patrick
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    className="p-1 px-2 border border-2 border-muted bg-warning rounded text-center nav-link"
                    to="/animations/badminton"
                  >
                    <motion.div
                      className="text-dark fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2 align-middle">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
          <Col sm={6} md={4}>
            <Card className="p-2 m-2 text-light">
              <Card.Img src={imgAnim[5]} width={100} alt={""} />
              <Card.ImgOverlay>
                <Card.Title>Pickleball</Card.Title>
                <Card.Text className="bg-dark-subtle px-2 mc-rfs-6 text-dark">
                  Tournois de novembre et de février.
                </Card.Text>
                <Nav className="ms-3 mb-3 position-absolute bottom-0 start-0">
                  <NavLink
                    className="p-1 px-2 border border-2 border-muted bg-danger rounded text-center nav-link"
                    to="/animations/pickleball"
                  >
                    <motion.div
                      className="text-light fw-semibold"
                      variants={buttonVariants}
                      whileHover="hover"
                    >
                      <span className="pe-2 align-middle">Voir</span>
                      <IconContext.Provider value={{ size: "1em" }}>
                        <FaChevronRight />
                      </IconContext.Provider>
                    </motion.div>
                  </NavLink>
                </Nav>
              </Card.ImgOverlay>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Animations;
