import React from "react";
import Header from "../components/sections/s-header";
import NavbarSection from "../components/sections/s-navbar";
import { Outlet } from "react-router-dom";
import Footer from "../components/sections/s-footer";
import { NavHeightProvider } from "../_utils/context/navheightprovider";
import BtnTopPage from "../_utils/tools/btn-toppage";
import { Container } from "react-bootstrap";

const PublicLayout = () => {
  return (
    <div className="layout" id="mcTopPage">
      <Container fluid className="gx-0">
        <Header />
        <NavHeightProvider>
          <NavbarSection />
          <Outlet />
          <BtnTopPage />
        </NavHeightProvider>
        <Footer />
      </Container>
    </div>
  );
};

export default PublicLayout;
