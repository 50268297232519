import img20231 from "../../../assets/img/img-feteecole/01-feteecole-2023-1599x1200.jpg";
import img20232 from "../../../assets/img/img-feteecole/02-feteecole-2023-2128x1197.jpg";
import img20233 from "../../../assets/img/img-feteecole/03-feteecole-2023-2128x1197.jpg";
import img20234 from "../../../assets/img/img-feteecole/04-feteecole-2023-1890x1080.jpg";
import img20235 from "../../../assets/img/img-feteecole/05-feteecole-2023-2388x1078.jpg";
import img20236 from "../../../assets/img/img-feteecole/06-feteecole-2023-1956x1077.jpg";
import img20237 from "../../../assets/img/img-feteecole/07-feteecole-2023-1040x780.jpg";
import img20238 from "../../../assets/img/img-feteecole/08-feteecole-2023-1530x2040.jpg";
import img20239 from "../../../assets/img/img-feteecole/09-feteecole-2023-1599x1200.jpg";
import img202310 from "../../../assets/img/img-feteecole/10-feteecole-2023-2000x900.jpg";
import img202311 from "../../../assets/img/img-feteecole/12-feteecole-2023-1600x1200.jpg";
import img202312 from "../../../assets/img/img-feteecole/12-feteecole-2023-1600x1200.jpg";
import img202313 from "../../../assets/img/img-feteecole/13-feteecole-2023-1600x1200.jpg";
import img202314 from "../../../assets/img/img-feteecole/14-feteecole-2023-1600x1200.jpg";
import img202315 from "../../../assets/img/img-feteecole/15-feteecole-2023-1496x749.jpg";
import img202316 from "../../../assets/img/img-feteecole/16-feteecole-2023-1599x1200.jpg";
import img202317 from "../../../assets/img/img-feteecole/17-feteecole-2023-1530x2040.jpg";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabFeteEcole = [
  {
    id: 1,
    season: "2023",
    num: 0,
    date: "",
    comment: "",
    images: [
      { img: img20231, width: "1599", height: "1200" },
      { img: img20232, width: "2128", height: "1197" },
      { img: img20233, width: "2128", height: "1197" },
      { img: img20234, width: "1890", height: "1080" },
      { img: img20235, width: "2388", height: "1078" },
      { img: img20236, width: "1956", height: "1077" },
      { img: img20237, width: "1040", height: "780" },
      { img: img20238, width: "1530", height: "2040" },
      { img: img20239, width: "1599", height: "1200" },
      { img: img202310, width: "2000", height: "900" },
      { img: img202311, width: "1599", height: "1200" },
      { img: img202312, width: "1600", height: "1200" },
      { img: img202313, width: "1600", height: "1200" },
      { img: img202314, width: "1600", height: "1200" },
      { img: img202315, width: "1496", height: "740" },
      { img: img202316, width: "1599", height: "1200" },
      { img: img202317, width: "1530", height: "2040" },
    ],
  },
];

const photosFeteEcole = tabFeteEcole.map((photo) => ({
  id: photo.id,
  season: photo.season,
  num: photo.num,
  date: photo.date,
  comment: photo.comment,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosFeteEcole;
