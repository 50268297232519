const tabTarifs = [
  { key: "10001001010000000", tariff: 115, offer: "Étudiant" },
  { key: "10001001001000000", tariff: 95, offer: "Étudiant" },
  { key: "10001001000100000", tariff: 85, offer: "Étudiant" },
  { key: "10001001000010000", tariff: 80, offer: "Étudiant" },
  { key: "10001001000001000", tariff: 75, offer: "Étudiant" },
  { key: "10001001000000100", tariff: 65, offer: "Étudiant" },
  { key: "10001001000000010", tariff: 60, offer: "Étudiant" },
  { key: "10001001000000001", tariff: 55, offer: "Étudiant" },
  { key: "01001001010000000", tariff: 85, offer: "Étudiant découverte" },
  { key: "01001001001000000", tariff: 65, offer: "Étudiant découverte" },
  { key: "01001001000100000", tariff: 55, offer: "Étudiant découverte" },
  { key: "01001001000010000", tariff: 50, offer: "Étudiant découverte" },
  { key: "01001001000001000", tariff: 45, offer: "Étudiant découverte" },
  { key: "01001001000000100", tariff: 35, offer: "Étudiant découverte" },
  { key: "01001001000000010", tariff: 30, offer: "Étudiant découverte" },
  { key: "01001001000000001", tariff: 25, offer: "Étudiant découverte" },
  { key: "10000101010000000", tariff: 245, offer: "Étudiant cours" },
  { key: "10000101001000000", tariff: 215, offer: "Étudiant cours" },
  { key: "10000101000100000", tariff: 195, offer: "Étudiant cours" },
  { key: "10000101000010000", tariff: 180, offer: "Étudiant cours" },
  { key: "10000101000001000", tariff: 165, offer: "Étudiant cours" },
  { key: "10000101000000100", tariff: 145, offer: "Étudiant cours" },
  { key: "10000101000000010", tariff: 125, offer: "Étudiant cours" },
  { key: "10000101000000001", tariff: 105, offer: "Étudiant cours" },
  { key: "00101010010000000", tariff: 95, offer: "Baby" },
  { key: "00101010001000000", tariff: 85, offer: "Baby" },
  { key: "00101010000100000", tariff: 75, offer: "Baby" },
  { key: "00101010000010000", tariff: 65, offer: "Baby" },
  { key: "00101010000001000", tariff: 55, offer: "Baby" },
  { key: "00101010000000100", tariff: 45, offer: "Baby" },
  { key: "00101010000000010", tariff: 40, offer: "Baby" },
  { key: "00101010000000001", tariff: 40, offer: "Baby" },
  { key: "00100110010000000", tariff: 145, offer: "Baby cours" },
  { key: "00100110001000000", tariff: 110, offer: "Baby cours" },
  { key: "00100110000100000", tariff: 100, offer: "Baby cours" },
  { key: "00100110000010000", tariff: 90, offer: "Baby cours" },
  { key: "00100110000001000", tariff: 80, offer: "Baby cours" },
  { key: "00100110000000100", tariff: 70, offer: "Baby cours" },
  { key: "00100110000000010", tariff: 60, offer: "Baby cours" },
  { key: "00100110000000001", tariff: 50, offer: "Baby cours" },
  { key: "00011010010000000", tariff: 105, offer: "Jeune" },
  { key: "00011010001000000", tariff: 95, offer: "Jeune" },
  { key: "00011010000100000", tariff: 85, offer: "Jeune" },
  { key: "00011010000010000", tariff: 75, offer: "Jeune" },
  { key: "00011010000001000", tariff: 65, offer: "Jeune" },
  { key: "00011010000000100", tariff: 55, offer: "Jeune" },
  { key: "00011010000000010", tariff: 50, offer: "Jeune" },
  { key: "00011010000000001", tariff: 50, offer: "Jeune" },
  { key: "00010110010000000", tariff: 185, offer: "Jeune cours" },
  { key: "00010110001000000", tariff: 150, offer: "Jeune cours" },
  { key: "00010110000100000", tariff: 140, offer: "Jeune cours" },
  { key: "00010110000010000", tariff: 130, offer: "Jeune cours" },
  { key: "00010110000001000", tariff: 120, offer: "Jeune cours" },
  { key: "00010110000000100", tariff: 110, offer: "Jeune cours" },
  { key: "00010110000000010", tariff: 100, offer: "Jeune cours" },
  { key: "00010110000000001", tariff: 90, offer: "Jeune cours" },
  { key: "10001000110000000", tariff: 170, offer: "Adulte" },
  { key: "10001000101000000", tariff: 145, offer: "Adulte" },
  { key: "10001000100100000", tariff: 135, offer: "Adulte" },
  { key: "10001000100010000", tariff: 125, offer: "Adulte" },
  { key: "10001000100001000", tariff: 115, offer: "Adulte" },
  { key: "10001000100000100", tariff: 105, offer: "Adulte" },
  { key: "10001000100000010", tariff: 95, offer: "Adulte" },
  { key: "10001000100000001", tariff: 85, offer: "Adulte" },
  { key: "01001000110000000", tariff: 150, offer: "Adulte découverte" },
  { key: "01001000101000000", tariff: 105, offer: "Adulte découverte" },
  { key: "01001000100100000", tariff: 95, offer: "Adulte découverte" },
  { key: "01001000100010000", tariff: 85, offer: "Adulte découverte" },
  { key: "01001000100001000", tariff: 75, offer: "Adulte découverte" },
  { key: "01001000100000100", tariff: 70, offer: "Adulte découverte" },
  { key: "01001000100000010", tariff: 65, offer: "Adulte découverte" },
  { key: "01001000100000001", tariff: 60, offer: "Adulte découverte" },
  { key: "10000100110000000", tariff: 285, offer: "Adulte cours" },
  { key: "10000100101000000", tariff: 230, offer: "Adulte cours" },
  { key: "10000100100100000", tariff: 210, offer: "Adulte cours" },
  { key: "10000100100010000", tariff: 195, offer: "Adulte cours" },
  { key: "10000100100001000", tariff: 160, offer: "Adulte cours" },
  { key: "10000100100000100", tariff: 135, offer: "Adulte cours" },
  { key: "10000100100000010", tariff: 115, offer: "Adulte cours" },
  { key: "10000100100000001", tariff: 90, offer: "Adulte cours" },
];

export default tabTarifs;
