// most of photos come from https://pixabay.com/fr/
import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoute from "../router/public/publicroute";
import AdminRoute from "../router/admin/adminroute";
import AuthRoute from "../router/auth/authroute";
import { AuthProvider } from "../_utils/context/authprovider";
import PrivateRoute from "../router/auth/privateroute";
import "../assets/css/styles.css";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/*" element={<PublicRoute />} />
        <Route path="/auth/*" element={<AuthRoute />} />
        <Route
          path="/admin/*"
          element={
            <PrivateRoute>
              <AdminRoute />
            </PrivateRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export default App;
