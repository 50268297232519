import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import imgFacebook from "../../assets/img/img-header/Facebook_logo.png";
import imgInstagram from "../../assets/img/img-header/Instagram_logo.png";
import imgX from "../../assets/img/img-header/X_logo_2023.svg";
import logoTCF from "../../assets/img/img-header/logoTCF1.png";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="mc-banner">
      <Container fluid>
        <Row>
          <Col xs={2}>
            <img
              className="img-fluid py-2"
              src={logoTCF}
              alt="Logo TCF ?"
              width={100}
            ></img>
          </Col>
          <Col xs={10} className="d-flex align-items-center">
            <Nav className="fw-bolder mc-rampart mc-rfs-1">
              <NavLink
                className="text-decoration-none text-light nav-link"
                to="/accueil"
              >
                TENNIS CLUB DE FEILLENS
              </NavLink>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row className="border border-top-0 border-start-0 border-end-0 border-bottom-1">
          <Col className="d-flex justify-content-end w-0">
            <OverlayTrigger
              placement={"top"}
              delay={{ show: 100, hide: 200 }}
              overlay={<Tooltip id={"tooltip-TCF"}>TCF sur facebook</Tooltip>}
            >
              <NavLink
                className="nav-link px-2"
                to="https://www.facebook.com/TCFeillens/?locale=fr_FR"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img
                  className="img-fluid pb-2"
                  src={imgFacebook}
                  alt="Logo Facebook ?"
                  width={20}
                  height={20}
                ></img>
              </NavLink>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              delay={{ show: 100, hide: 200 }}
              overlay={<Tooltip id={"tooltip-TCF"}>TCF sur Instagram</Tooltip>}
            >
              <NavLink
                className="nav-link px-2"
                href="https://www.instagram.com/p/ByAiUIQijYq/"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img
                  className="img-fluid pb-2"
                  src={imgInstagram}
                  alt="Logo Instagram ?"
                  width={20}
                  height={20}
                ></img>
              </NavLink>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"top"}
              delay={{ show: 100, hide: 200 }}
              overlay={<Tooltip id={"tooltip-TCF"}>TCF sur X(Twitter)</Tooltip>}
            >
              <NavLink
                className="nav-link px-2"
                href="https://twitter.com/search?q=tennis%20club%20de%20feillens"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img
                  className="img-fluid pb-2"
                  src={imgX}
                  alt="Logo X(ex-twitter) ?"
                  width={20}
                  height={20}
                ></img>
              </NavLink>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
