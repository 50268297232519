import img202400 from "../../../assets/img/img-tennissante/00-tennissante-2024-1500x836.jpg";
import img202401 from "../../../assets/img/img-tennissante/01-tennissante-2024-1000x450.jpg";
import img202402 from "../../../assets/img/img-tennissante/02-tennissante-2024-800x296.jpg";
import img202403 from "../../../assets/img/img-tennissante/03-tennissante-2024-1000x451.jpg";
import img202404 from "../../../assets/img/img-tennissante/04-tennissante-2024-1500x605.jpg";
import img202405 from "../../../assets/img/img-tennissante/05-tennissante-2024-800x438.jpg";
import img202406 from "../../../assets/img/img-tennissante/06-tennissante-2024-1000x507.jpg";
import img202407 from "../../../assets/img/img-tennissante/07-tennissante-2024-1500x734.jpg";
import img202408 from "../../../assets/img/img-tennissante/08-tennissante-2024-700x355.jpg";
import img202409 from "../../../assets/img/img-tennissante/09-tennissante-2024-1000x450.jpg";
import img202410 from "../../../assets/img/img-tennissante/10-tennissante-2024-1200x436.jpg";
import img202411 from "../../../assets/img/img-tennissante/11-tennissante-2024-1300x487.jpg";
import img202412 from "../../../assets/img/img-tennissante/12-tennissante-2024-1400x522.jpg";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabTennisSante = [
  {
    id: 1,
    season: "2024",
    num: 0,
    date: "26/10/2023",
    comment: "3 sessions de 10 séances d'octobre à juin.",
    images: [
      { img: img202400, width: "1500", height: "836" },
      { img: img202401, width: "1000", height: "450" },
      { img: img202402, width: "800", height: "296" },
      { img: img202403, width: "1000", height: "451" },
      { img: img202404, width: "1500", height: "605" },
      { img: img202405, width: "800", height: "438" },
      { img: img202406, width: "1000", height: "507" },
      { img: img202407, width: "1500", height: "734" },
      { img: img202408, width: "700", height: "355" },
      { img: img202409, width: "1000", height: "450" },
      { img: img202410, width: "1200", height: "436" },
      { img: img202411, width: "1300", height: "487" },
      { img: img202412, width: "1400", height: "522" },
    ],
  },
];

const photosTennisSante = tabTennisSante.map((photo) => ({
  id: photo.id,
  season: photo.season,
  num: photo.num,
  date: photo.date,
  comment: photo.comment,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosTennisSante;
