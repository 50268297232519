import img20241 from "../../../assets/img/img-stages/01-stages-2024-affiche-780x1080.png";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabStages = [
  {
    id: 1,
    season: "2024",
    num: 0,
    date: "",
    comment: "",
    images: [{ img: img20241, width: "2100", height: "2970" }],
  },
];

const photosStages = tabStages.map((photo) => ({
  id: photo.id,
  season: photo.season,
  num: photo.num,
  date: photo.date,
  comment: photo.comment,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosStages;
